// Data converted from your CSVs
export const maleData = [
    { age: 3, meanSkeletalAge: 3.01, stdDev: 0.69 },
    { age: 6, meanSkeletalAge: 6.09, stdDev: 1.13 },
    { age: 9, meanSkeletalAge: 9.56, stdDev: 1.43 },
    { age: 12, meanSkeletalAge: 12.74, stdDev: 1.97 },
    { age: 18, meanSkeletalAge: 19.36, stdDev: 3.52 },
    { age: 24, meanSkeletalAge: 25.97, stdDev: 3.92 },
    { age: 30, meanSkeletalAge: 32.40, stdDev: 4.52 },
    { age: 36, meanSkeletalAge: 38.21, stdDev: 5.08 },
    { age: 42, meanSkeletalAge: 43.89, stdDev: 5.40 },
    { age: 48, meanSkeletalAge: 49.04, stdDev: 6.66 },
    { age: 54, meanSkeletalAge: 56.00, stdDev: 8.36 },
    { age: 60, meanSkeletalAge: 62.43, stdDev: 8.79 },
    { age: 72, meanSkeletalAge: 75.46, stdDev: 9.17 },
    { age: 84, meanSkeletalAge: 88.20, stdDev: 8.90 },
    { age: 96, meanSkeletalAge: 101.38, stdDev: 9.10 },
    { age: 108, meanSkeletalAge: 113.90, stdDev: 9.00 },
    { age: 120, meanSkeletalAge: 125.68, stdDev: 9.79 },
    { age: 132, meanSkeletalAge: 137.32, stdDev: 10.09 },
    { age: 144, meanSkeletalAge: 148.82, stdDev: 10.38 },
    { age: 156, meanSkeletalAge: 158.39, stdDev: 10.44 },
    { age: 168, meanSkeletalAge: 170.02, stdDev: 10.72 },
    { age: 180, meanSkeletalAge: 182.72, stdDev: 11.32 },
    { age: 192, meanSkeletalAge: 195.32, stdDev: 12.86 },
    { age: 204, meanSkeletalAge: 206.21, stdDev: 13.05 }
];

export const femaleData = [
    { age: 3, meanSkeletalAge: 3.02, stdDev: 0.72 },
    { age: 6, meanSkeletalAge: 6.04, stdDev: 1.16 },
    { age: 9, meanSkeletalAge: 9.05, stdDev: 1.36 },
    { age: 12, meanSkeletalAge: 12.04, stdDev: 1.77 },
    { age: 18, meanSkeletalAge: 18.22, stdDev: 3.49 },
    { age: 24, meanSkeletalAge: 24.16, stdDev: 4.64 },
    { age: 30, meanSkeletalAge: 30.96, stdDev: 5.37 },
    { age: 36, meanSkeletalAge: 36.63, stdDev: 5.97 },
    { age: 42, meanSkeletalAge: 43.50, stdDev: 7.48 },
    { age: 48, meanSkeletalAge: 50.14, stdDev: 8.98 },
    { age: 54, meanSkeletalAge: 60.06, stdDev: 10.73 },
    { age: 60, meanSkeletalAge: 66.21, stdDev: 11.65 },
    { age: 72, meanSkeletalAge: 78.40, stdDev: 10.23 },
    { age: 84, meanSkeletalAge: 89.30, stdDev: 9.64 },
    { age: 96, meanSkeletalAge: 100.66, stdDev: 10.23 },
    { age: 108, meanSkeletalAge: 113.86, stdDev: 10.74 },
    { age: 120, meanSkeletalAge: 125.66, stdDev: 11.73 },
    { age: 132, meanSkeletalAge: 137.87, stdDev: 11.94 },
    { age: 144, meanSkeletalAge: 149.62, stdDev: 10.24 },
    { age: 156, meanSkeletalAge: 162.28, stdDev: 10.67 },
    { age: 168, meanSkeletalAge: 174.24, stdDev: 11.30 },
    { age: 180, meanSkeletalAge: 183.62, stdDev: 9.23 },
    { age: 192, meanSkeletalAge: 189.44, stdDev: 7.31 }
];