import React from 'react';
import { Typography, Container } from '@mui/material';

function Construcao() {
  return (
    <Container sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h4">Em Construção</Typography>
    </Container>
  );
}

export default Construcao;
