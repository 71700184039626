import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Create MUI theme for spacing
const theme = createTheme();

export default function RmFerro() {
  const [R2, set_R2] = useState("");
  const [T2, set_T2] = useState("");
  const [LIC_mgg, set_LIC_mgg] = useState("");
  const [LIC_umolg, set_LIC_umolg] = useState("");
  const [severidade_, set_severidade_] = useState("");
  const [bg_severidade_, set_bg_severidade_] = useState("#00443322");
  const [tesla, setTesla] = useState("1.5");

  useEffect(() => {
    let valor = parseFloat(R2);

    if (isNaN(valor) || valor <= 0) {
      set_T2("");
      set_LIC_mgg("");
      set_LIC_umolg("");
      set_severidade_("");
      set_bg_severidade_("#00443322");
      return;
    }

    let calculatedT2 = ((1 / valor) * 1000).toFixed(2);

    let calculatedLIC_mgg;
    let calculatedLIC_umolg;

    if (tesla === "1.5") {
      calculatedLIC_mgg = (0.0254 * (valor + 0.202)).toFixed(2);
      calculatedLIC_umolg = (calculatedLIC_mgg * 18).toFixed(2);
    } else if (tesla === "3.0") {
      calculatedLIC_umolg = (valor / 3.2).toFixed(2);
      calculatedLIC_mgg = (calculatedLIC_umolg / 18).toFixed(2);
    }

    let severidade;
    let bg_serveridade;

    if (calculatedLIC_mgg < 2) {
      severidade = "Normal";
      bg_serveridade = "#9fc5e8";
    } else if (calculatedLIC_mgg >= 2 && calculatedLIC_mgg < 4) {
      severidade = "Insignificante";
      bg_serveridade = "#a2c4c9";
    } else if (calculatedLIC_mgg >= 4 && calculatedLIC_mgg < 6) {
      severidade = "Leve";
      bg_serveridade = "#b6d7a8";
    } else if (calculatedLIC_mgg >= 6 && calculatedLIC_mgg < 8) {
      severidade = "Moderado";
      bg_serveridade = "#ffe599";
    } else if (calculatedLIC_mgg >= 8 && calculatedLIC_mgg < 16) {
      severidade = "Moderado-acentuado";
      bg_serveridade = "#f9cb9c";
    } else if (calculatedLIC_mgg >= 16) {
      severidade = "Acentuado";
      bg_serveridade = "#dd7e6b";
    }

    set_severidade_(severidade);
    set_bg_severidade_(bg_serveridade);
    set_T2(calculatedT2);
    set_LIC_mgg(calculatedLIC_mgg);
    set_LIC_umolg(calculatedLIC_umolg);
  }, [R2, tesla]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: theme.spacing(2), textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Quantificação de Ferro Hepático
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ backgroundColor: "#00443322", padding: theme.spacing(2) }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tesla</FormLabel>
                <RadioGroup
                  row
                  aria-label="tesla"
                  name="tesla"
                  value={tesla}
                  onChange={(e) => setTesla(e.target.value)}
                >
                  <FormControlLabel value="1.5" control={<Radio />} label="1.5T" />
                  <FormControlLabel value="3.0" control={<Radio />} label="3.0T" />
                </RadioGroup>
              </FormControl>
              <Typography variant="h6">R2*</Typography>
              <TextField
                id="R2"
                fullWidth
                value={R2}
                onChange={(e) => set_R2(e.target.value)}
                type="number"
                inputProps={{ min: 0 }}
              />
              <Typography variant="h6" style={{ marginTop: theme.spacing(2) }}>
                T2*
              </Typography>
              <TextField id="T2" fullWidth value={isFinite(T2) ? T2 : ""} readOnly />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              style={{
                backgroundColor: bg_severidade_,
                padding: theme.spacing(2),
              }}
            >
              <Typography variant="h5">{severidade_}</Typography>
              <Typography variant="h6">{LIC_mgg} mg/g</Typography>
              <Typography variant="h6">{LIC_umolg} µmol/g</Typography>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <div>
            <Typography variant="body2">
              * Fórmula de calibração de Wood et al. Blood 2005.
            </Typography>
            <Typography variant="body2">
              Graduação de gravidade de Henninger et al. Eur Radiol 2020.
            </Typography>
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
