import React, { useState } from 'react';
import {
  Paper,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import tireoide from '../../../../assets/tireoide.PNG'; // Adjust the path based on your assets location

// Data dictionaries with 'texto' property added
const composicaoOptions = {
  cistico: {
    titulo: 'Cístico ou quase completamente cístico',
    texto: 'cístico',
    pontos: 0,
  },
  espongiforme: { titulo: 'Espongiforme', texto: 'espongiforme', pontos: 0 },
  misto: {
    titulo: 'Misto sólido-cístico',
    texto: 'sólido-cístico',
    pontos: 1,
  },
  solido: {
    titulo: 'Sólido ou quase completamente sólido',
    texto: 'sólido',
    pontos: 2,
  },
  indeterminado: {
    titulo: 'Indeterminado devido a calcificação',
    texto: 'de composição indeterminada',
    pontos: 2,
  },
};

const ecogenicidadeOptions = {
  anecoico: { titulo: 'Anecoico', texto: 'anecoico', pontos: 0 },
  isoecoico: {
    titulo: 'Hiperecoico ou Isoecoico',
    texto: 'isoecoico/hiperecoico',
    pontos: 1,
  },
  hipoecoico: { titulo: 'Hipoecoico', texto: 'hipoecoico', pontos: 2 },
  ahipoecoico: {
    titulo: 'Acentuadamente Hipoecoico',
    texto: 'acentuadamente hipoecoico',
    pontos: 3,
  },
  indeterminada: {
    titulo: 'Indeterminado',
    texto: 'ecogenicidade indeterminada',
    pontos: 1,
  },
};

const margensOptions = {
  circunscrito: { titulo: 'Bem definidas', texto: 'circunscrito', pontos: 0 },
  maldefinidas: {
    titulo: 'Mal definidas',
    texto: 'margens mal definidas',
    pontos: 0,
  },
  lobuladas: {
    titulo: 'Lobuladas ou Irregulares',
    texto: 'margens lobuladas',
    pontos: 2,
  },
  irregularesee: {
    titulo: 'Extensão Extra-tireoideana',
    texto: 'margens irregulares com extensão extra-tireoidea',
    pontos: 3,
  },
  indeterminadas: {
    titulo: 'Indeterminadas',
    texto: 'margens indeterminadas',
    pontos: 0,
  },
};

const formatoOptions = {
  paralelo: {
    titulo: 'Maior eixo paralelo à pele',
    texto: 'maior eixo paralelo à pele',
    pontos: 0,
  },
  perpendicular: {
    titulo: 'Maior eixo perpendicular à pele',
    texto: 'maior eixo perpendicular à pele',
    pontos: 3,
  },
};

const calcificacoesOptions = {
  sem: {
    titulo: 'Nenhuma ou com cauda de cometa',
    texto: 'sem calcificações',
    pontos: 0,
  },
  macro: {
    titulo: 'Macrocalcificações',
    texto: 'com macrocalcificações',
    pontos: 1,
  },
  perifericas: {
    titulo: 'Periféricas',
    texto: 'com calcificações periféricas',
    pontos: 2,
  },
  puntiformes: {
    titulo: 'Puntiformes',
    texto: 'com calcificações puntiformes',
    pontos: 3,
  },
};

const localizacaoOptions = {
  dsuperior: { texto: 'no terço superior do lobo direito' },
  dmedio: { texto: 'no terço médio do lobo direito' },
  dinferior: { texto: 'no terço inferior do lobo direito' },
  istmo: { texto: 'no istmo' },
  esuperior: { texto: 'no terço superior do lobo esquerdo' },
  emedio: { texto: 'no terço médio do lobo esquerdo' },
  einferior: { texto: 'no terço inferior do lobo esquerdo' },
};

const vascularizacaoOptions = {
  I: {
    titulo: 'I: ausência de vascularização',
    texto: 'ausência de vascularização',
  },
  II: { titulo: 'II: apenas periférica', texto: 'apenas vascularização periférica' },
  III: {
    titulo: 'III: periférica maior ou igual à central',
    texto: 'vascularização periférica maior ou igual à central',
  },
  IV: {
    titulo: 'IV: central maior que a periférica',
    texto: 'vascularização central maior que a periférica',
  },
  V: { titulo: 'V: apenas central', texto: 'apenas vascularização central' },
};

const recommendations = {
  1: 'Benigno. Não recomendada PAAF.',
  2: 'Não suspeito. Não recomendada PAAF.',
  3: 'Pouco suspeito. PAAF se >= 2,5 cm. Seguimento se >= 1,5 cm.',
  4: 'Moderadamente suspeito. PAAF se >= 1,5 cm. Seguimento se >= 1,0 cm.',
  5: 'Muito suspeito. PAAF se >= 1,0 cm. Seguimento se >= 0,5 cm.',
};

// Main Component
function Tirads() {
  // State variables
  const [composicao, setComposicao] = useState('cistico');
  const [ecogenicidade, setEcogenicidade] = useState('anecoico');
  const [margens, setMargens] = useState('circunscrito');
  const [formato, setFormato] = useState('paralelo');
  const [calcificacoes, setCalcificacoes] = useState('sem');
  const [localizacao, setLocalizacao] = useState('dsuperior');
  const [vascularizacao, setVascularizacao] = useState('');
  const [medida1, setMedida1] = useState('');
  const [medida2, setMedida2] = useState('');
  const [medida3, setMedida3] = useState('');
  const [checkIconVisible, setCheckIconVisible] = useState(false);

  // Functions for handling changes
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
    setCheckIconVisible(false);
  };

  // Calculations
  const somatoriaPontos =
    composicaoOptions[composicao].pontos +
    ecogenicidadeOptions[ecogenicidade].pontos +
    margensOptions[margens].pontos +
    formatoOptions[formato].pontos +
    calcificacoesOptions[calcificacoes].pontos;

  // Determine TIRADS level
  let tirads = 1;
  if (somatoriaPontos === 2) tirads = 2;
  else if (somatoriaPontos === 3) tirads = 3;
  else if (somatoriaPontos >= 4 && somatoriaPontos < 7) tirads = 4;
  else if (somatoriaPontos >= 7) tirads = 5;

  // Construct report text
  const medidasString = `${medida1 || ''} x ${medida2 || ''} x ${medida3 || ''}`;
  let textoLaudo = `Nódulo ${composicaoOptions[composicao].texto}, ${ecogenicidadeOptions[ecogenicidade].texto}, ${margensOptions[margens].texto}, ${formatoOptions[formato].texto}, ${calcificacoesOptions[calcificacoes].texto}, localizado ${localizacaoOptions[localizacao].texto}, medindo ${medidasString} cm (ACR-TIRADS: ${tirads})`;

  if (vascularizacao) {
    textoLaudo += `. Ao estudo Doppler observa-se ${vascularizacaoOptions[vascularizacao].texto} (Chammas ${vascularizacao}).`;
  } else {
    textoLaudo += '.';
  }

  // Copy text to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textoLaudo);
    setCheckIconVisible(true);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Calculadora ACR TI-RADS
      </Typography>

      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <AttributeSection
            title="Composição"
            options={composicaoOptions}
            value={composicao}
            onChange={handleChange(setComposicao)}
          />

          <AttributeSection
            title="Ecogenicidade"
            options={ecogenicidadeOptions}
            value={ecogenicidade}
            onChange={handleChange(setEcogenicidade)}
          />

          <AttributeSection
            title="Margens"
            options={margensOptions}
            value={margens}
            onChange={handleChange(setMargens)}
          />

          <AttributeSection
            title="Formato"
            options={formatoOptions}
            value={formato}
            onChange={handleChange(setFormato)}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <AttributeSection
            title="Calcificações"
            options={calcificacoesOptions}
            value={calcificacoes}
            onChange={handleChange(setCalcificacoes)}
          />

          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">Medidas:</Typography>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item xs={3}>
                <TextField
                  label="Medida 1"
                  value={medida1}
                  onChange={(e) => setMedida1(e.target.value)}
                />
              </Grid>
              <Grid item>x</Grid>
              <Grid item xs={3}>
                <TextField
                  label="Medida 2"
                  value={medida2}
                  onChange={(e) => setMedida2(e.target.value)}
                />
              </Grid>
              <Grid item>x</Grid>
              <Grid item xs={3}>
                <TextField
                  label="Medida 3"
                  value={medida3}
                  onChange={(e) => setMedida3(e.target.value)}
                />
              </Grid>
            </Grid>
          </Paper>

          <LocalizationSection
            localizacao={localizacao}
            setLocalizacao={setLocalizacao}
            handleChange={handleChange}
          />

          <AttributeSection
            title="Vascularização Chammas (opcional)"
            options={vascularizacaoOptions}
            value={vascularizacao}
            onChange={handleChange(setVascularizacao)}
          />

          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">Soma de pontos: {somatoriaPontos}</Typography>
            <Typography variant="h4">ACR-TIRADS: {tirads}</Typography>
            <Typography variant="h6">Recomendação:</Typography>
            <Typography>{recommendations[tirads]}</Typography>
          </Paper>

          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Texto sugerido:</Typography>
            <TextField
              variant="outlined"
              multiline
              value={textoLaudo}
              rows={6}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={copyToClipboard}
              sx={{ mt: 1 }}
            >
              Copiar texto
            </Button>
            {checkIconVisible && <CheckIcon color="success" sx={{ ml: 1 }} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Tirads;

// Sub-component for Attributes
function AttributeSection({ title, options, value, onChange }) {
  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup value={value} onChange={onChange}>
          {Object.keys(options).map((key) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={`${options[key].titulo} (${options[key].pontos})`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
}

// Sub-component for Localization with image
function LocalizationSection({ localizacao, setLocalizacao, handleChange }) {
  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Localização</FormLabel>
        <RadioGroup
          name="localizacao"
          value={localizacao}
          onChange={handleChange(setLocalizacao)}
        >
          <Box
            sx={{
              backgroundImage: `url(${tireoide})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: 2,
            }}
          >
            <Grid container spacing={1}>
              {/* Lobo direito */}
              <Grid item xs={4}>
                <FormControlLabel
                  value="dsuperior"
                  control={<Radio />}
                  label="Superior"
                />
                <FormControlLabel
                  value="dmedio"
                  control={<Radio />}
                  label="Médio"
                />
                <FormControlLabel
                  value="dinferior"
                  control={<Radio />}
                  label="Inferior"
                />
              </Grid>

              {/* Istmo */}
              <Grid item xs={4}>
                <FormControlLabel
                  value="istmo"
                  control={<Radio />}
                  label="Istmo"
                />
              </Grid>

              {/* Lobo esquerdo */}
              <Grid item xs={4}>
                <FormControlLabel
                  value="esuperior"
                  control={<Radio />}
                  label="Superior"
                />
                <FormControlLabel
                  value="emedio"
                  control={<Radio />}
                  label="Médio"
                />
                <FormControlLabel
                  value="einferior"
                  control={<Radio />}
                  label="Inferior"
                />
              </Grid>
            </Grid>
          </Box>
        </RadioGroup>
      </FormControl>

      {/* Labels for Lobes */}
      <Grid container sx={{ marginTop: 2 }}>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          Lobo direito
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          Lobo esquerdo
        </Grid>
      </Grid>
    </Paper>
  );
}
