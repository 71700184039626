import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Typography,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Theme creation (if necessary)
const theme = createTheme();

function ORadsRM() {
  const [currentNode, setCurrentNode] = useState(oradsRMTree);
  const [path, setPath] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [orads, setOrads] = useState(null);

  useEffect(() => {
    setCurrentNode(oradsRMTree);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    const newPath = [...path, value];
    setPath(newPath);

    const nextNode = getValueAtPath(oradsRMTree, newPath);

    if (typeof nextNode === 'number') {
      setOrads(nextNode);
    } else {
      setCurrentNode(nextNode);
    }
  };

  const handleBack = () => {
    if (path.length === 0) return;
    const newPath = path.slice(0, -1);
    setPath(newPath);
    setSelectedOption(newPath[newPath.length - 1] || '');
    const prevNode = getValueAtPath(oradsRMTree, newPath);
    setCurrentNode(prevNode);
    setOrads(null);
  };

  const handleReset = () => {
    setCurrentNode(oradsRMTree);
    setPath([]);
    setSelectedOption('');
    setOrads(null);
  };

  const getValueAtPath = (obj, pathArray) => {
    return pathArray.reduce((acc, key) => (acc ? acc[key] : null), obj);
  };

  const dictPalavras = {
    conteudo_lipidico: "Conteúdo lipídico",
    conteudo_solido: "Conteúdo sólido",
    outro: "Outro",
    paraovariano: "Paraovariano",
    tubario: "Tubário",
    peritoneal: "Peritoneal",
    ovariano: "Ovariano",
    unilocular: "Unilocular",
    multilocular: "Multilocular",
    componenteSolido: "Componente sólido",
    liquido_simples: "Líquido simples",
    conteudo_hemorragico: "Conteúdo hemorrágico",
    sem_realce: "Sem realce",
    realce_da_parede: "Realce da parede",
    fina: "Fina",
    espessa: "Espessa",
    pre_menopausa: "Pré-menopausa",
    pos_menopausa: "Pós-menopausa",
    menor_3cm: "Menor que 3 cm",
    maior_3cm: "Maior que 3 cm",
    endometrioma: "Endometrioma",
    cisto_unilocular: "Cisto unilocular",
    qualquer_Fluido: "Qualquer fluido",
    sem_componente_lipidico: "Sem componente lipídico",
    septos_finos_eou_parede_com_realce_sem_componente_solido_com_realce:
      "Septos finos e/ou parede com realce sem componente sólido com realce.",
    com_componente_lipidico: "Com componente lipídico",
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          padding: 2,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Calculadora O-RADS RM - <u>em construção</u>
        </Typography>
        {orads === null ? (
          <>
            <FormControl component="fieldset">
              <FormLabel component="legend">Selecione uma opção</FormLabel>
              <RadioGroup value={selectedOption} onChange={handleChange}>
                {currentNode &&
                  Object.keys(currentNode).map((key) => (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={dictPalavras[key] || key}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleBack}
              disabled={path.length === 0}
              sx={{ mt: 2 }}
            >
              Voltar
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h5">O-RADS RM: {orads}</Typography>
            <Button
              variant="contained"
              onClick={handleReset}
              sx={{ mt: 2 }}
            >
              Resetar
            </Button>
          </>
        )}
      </Paper>
    </ThemeProvider>
  );
}

export default ORadsRM;

// Decision tree and dictionary
const oradsRMTree = {
  paraovariano: {
    cisto_de_inclusao: 2,
    outro: 0,
  },
  tubario: {
    hidrossalpinge: 2,
    outro: 0,
  },
  peritoneal: {},
  ovariano: {
    unilocular: {
      liquido_simples: {
        sem_realce: {
          pre_menopausa: {
            menor_3cm: 1,
            maior_3cm: 2,
          },
          pos_menopausa: 2,
        },
        realce_da_parede: {
          fina: {
            pre_menopausa: {
              menor_3cm: 1,
              maior_3cm: 2,
            },
            pos_menopausa: 2,
          },
          espessa: { pre_menopausa: { menos_3cm: 1 } },
        },
      },
      conteudo_hemorragico: {
        sem_realce: {
          endometrioma: 2,
          menor_3cm: 1,
          maior_3cm: 2,
        },
        realce_da_parede: {
          pre_menopausa: {
            endometrioma: 2,
            menor_3cm: 1,
            maior_3cm: 3,
          },
          pos_menopausa: {
            endometrioma: 2,
            cisto_unilocular: 3,
          },
        },
      },
    },
    multilocular: {
      qualquer_Fluido: {
        sem_componente_lipidico: {
          septos_finos_eou_parede_com_realce_sem_componente_solido_com_realce: 3,
          outro: {
            conteudo_lipidico: {
              sem_componente_realce: 2,
              pequeno_componente_com_realce: 2,
              compoente_com_realce: 4,
            },
            conteudo_solido: {
              homogeneamente_baixo_t2_dwi: 2,
              componente_com_realce: {
                DCE_baixo_risco: 3,
                DCE_intermediario: 4,
                DCE_alto_risco: 5,
                sem_DCE_realce_maior_miom: 4,
                sem_DCE_realce_menor_miom: 5,
              },
            },
          },
        },
      },
      com_componente_lipidico: {},
    },
    componenteSolido: {
      conteudo_lipidico: {
        sem_componente_realce: 2,
        pequeno_componente_com_realce: 2,
        compoente_com_realce: 4,
      },
      conteudo_solido: {
        homogeneamente_baixo_t2_dwi: 2,
        componente_com_realce: {
          DCE_baixo_risco: 3,
          DCE_intermediario: 4,
          DCE_alto_risco: 5,
          sem_DCE_realce_maior_miom: 4,
          sem_DCE_realce_menor_miom: 5,
        },
      },
    },
  },
};
