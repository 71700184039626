import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Create MUI theme for spacing
const theme = createTheme();

export default function LiverFatCalculator() {
  const [HU, setHU] = useState("");
  const [PDFF, setPDFF] = useState("");
  const [severity, setSeverity] = useState("");
  const [bgSeverity, setBgSeverity] = useState("#00443322");
  const [reportText, setReportText] = useState("");
  const [conclusionText, setConclusionText] = useState("");

  const handleChange = (e) => {
    let value = parseFloat(e.target.value);

    if (isNaN(value)) {
      setPDFF("");
      setSeverity("");
      setBgSeverity("#00443322");
      setReportText("");
      setConclusionText("");
      return;
    }

    // Calculate PDFF using PDFF = –0.58 × HU + 38.2
    let calculatedPDFF = (-0.58 * value + 38.2).toFixed(2);

    let severityLevel;
    let bgSeverityColor;
    let textPhrase = "";
    let conclusionPhrase = "";

    if (calculatedPDFF < 5) {
      severityLevel = "Normal";
      bgSeverityColor = "#9fc5e8";
      calculatedPDFF = ""; // Clear PDFF when severity is "Normal"
    } else if (calculatedPDFF >= 5 && calculatedPDFF < 15) {
      severityLevel = "Leve";
      bgSeverityColor = "#b6d7a8";
      textPhrase = `Redução difusa do coeficiente de atenuação hepático com áreas de preservação focal adjacente a fissuras e à região peri-vesicular com fração de gordura estimada em ${calculatedPDFF}%.`;
      conclusionPhrase = "Doença gordurosa hepática leve.";
    } else if (calculatedPDFF >= 15 && calculatedPDFF < 25) {
      severityLevel = "Moderada";
      bgSeverityColor = "#ffe599";
      textPhrase = `Redução difusa do coeficiente de atenuação hepático com áreas de preservação focal adjacente a fissuras e à região peri-vesicular com fração de gordura estimada em ${calculatedPDFF}%.`;
      conclusionPhrase = "Doença gordurosa hepática moderada.";
    } else if (calculatedPDFF >= 25) {
      severityLevel = "Acentuada";
      bgSeverityColor = "#f9cb9c";
      textPhrase = `Redução difusa do coeficiente de atenuação hepático com áreas de preservação focal adjacente a fissuras e à região peri-vesicular com fração de gordura estimada em ${calculatedPDFF}%.`;
      conclusionPhrase = "Doença gordurosa hepática acentuada.";
    } else {
      severityLevel = "";
      bgSeverityColor = "#00443322";
    }

    setPDFF(severityLevel === "Normal" ? "" : calculatedPDFF);
    setSeverity(severityLevel);
    setBgSeverity(bgSeverityColor);

    // Only set report text if severity is not "Normal"
    setReportText(severityLevel !== "Normal" ? textPhrase : "");
    setConclusionText(conclusionPhrase);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: theme.spacing(2), textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Calculador de fração de gordura hepática na TC
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ backgroundColor: "#00443322", padding: theme.spacing(2) }}>
              <Typography variant="h6">HU sem contraste</Typography>
              <TextField
                id="HU"
                fullWidth
                value={HU}
                onChange={(e) => {
                  setHU(e.target.value);
                  handleChange(e);
                }}
                type="number"
                inputProps={{ min: 0 }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{ backgroundColor: bgSeverity, padding: theme.spacing(2) }}>
              <Typography variant="h5">{severity}</Typography>
              {severity !== "Normal" && (
                <Typography variant="h6">PDFF: {PDFF}%</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
        <br />
        {/* Text box for report description */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="report-text"
              label="Texto do laudo"
              fullWidth
              multiline
              rows={3}
              value={reportText}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => copyToClipboard(reportText)}
              sx={{ marginTop: theme.spacing(2) }}
            >
              Copiar para área de transferência
            </Button>
          </Grid>
        </Grid>
        <br />
        {/* Text box for conclusion */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="conclusion-text"
              label="Conclusão"
              fullWidth
              multiline
              rows={2}
              value={conclusionText}
              InputProps={{
                readOnly: true,
              }}
            />
            {conclusionText && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => copyToClipboard(conclusionText)}
                sx={{ marginTop: theme.spacing(2) }}
              >
                Copiar conclusão para área de transferência
              </Button>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <div>
            <Typography variant="body2">
              * Calculado com equação de Perry J. Pickhardt et al., AJR 2018.
            </Typography>
            <Typography variant="body2">
              PDFF = –0.58 × HU + 38.2
            </Typography>
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
