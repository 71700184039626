import React, { useState } from 'react';
import {
  Paper,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

function BiradsUltrassom() {
  // Opções revisadas
  const formaOptions = [
    { value: 'oval', label: 'Oval', descricao: 'oval' },
    { value: 'redondo', label: 'Redondo', descricao: 'redondo' },
    { value: 'irregular', label: 'Irregular', descricao: 'irregular' },
  ];

  const orientacaoOptions = [
    { value: 'paralela', label: 'Paralela', descricao: 'paralela' },
    { value: 'nao_paralela', label: 'Não paralela', descricao: 'não paralela' },
  ];

  const margemOptions = [
    { value: 'circunscritas', label: 'Circunscritas', descricao: 'circunscritas' },
    { value: 'indistintas', label: 'Indistintas', descricao: 'indistintas' },
    { value: 'angulares', label: 'Angulares', descricao: 'angulares' },
    { value: 'microlobuladas', label: 'Microlobuladas', descricao: 'microlobuladas' },
    { value: 'espiculadas', label: 'Espiculadas', descricao: 'espiculadas' },
  ];

  const ecogenicidadeOptions = [
    { value: 'anecoico', label: 'Anecoico', descricao: 'anecoico' },
    {
      value: 'hipoecogenico',
      label: 'Hipoecogênico',
      descricao: 'hipoecogênico em relação à gordura',
    },
    {
      value: 'isoecogenico',
      label: 'Isoecogênico',
      descricao: 'isoecogênico em relação à gordura',
    },
    {
      value: 'hiperecogenico',
      label: 'Hiperecogênico',
      descricao: 'hiperecogênico em relação à gordura',
    },
    {
      value: 'complexo',
      label: 'Complexo cístico e sólido',
      descricao: 'complexo cístico e sólido',
    },
  ];

  const efeitoPosteriorOptions = [
    {
      value: 'ausente',
      label: 'Ausente',
      descricao: 'sem alteração acústica posterior',
    },
    {
      value: 'reforco',
      label: 'Reforço acústico posterior',
      descricao: 'com reforço acústico posterior',
    },
    {
      value: 'sombra',
      label: 'Sombra acústica posterior',
      descricao: 'com sombra acústica posterior',
    },
    {
      value: 'padrao_combinado',
      label: 'Padrão combinado',
      descricao: 'com padrão acústico combinado',
    },
  ];

  // Estados dos atributos
  const [forma, setForma] = useState('');
  const [orientacao, setOrientacao] = useState('');
  const [margem, setMargem] = useState('');
  const [ecogenicidade, setEcogenicidade] = useState('');
  const [efeitoPosterior, setEfeitoPosterior] = useState('');
  const [alteracoesAssociadas, setAlteracoesAssociadas] = useState({
    arquitetura: false,
    ducto: false,
    pele: false,
    edema: false,
    elastografia: false,
  });
  const [categoria, setCategoria] = useState('');
  const [checkIconVisible, setCheckIconVisible] = useState(false);

  // Localização
  const [lado, setLado] = useState('');
  const [relogio, setRelogio] = useState('');
  const [distancia, setDistancia] = useState('');

  // Medidas
  const [medida1, setMedida1] = useState('');
  const [medida2, setMedida2] = useState('');
  const [medida3, setMedida3] = useState('');

  // Função para lidar com as alterações associadas
  const handleAlteracoesAssociadasChange = (event) => {
    setAlteracoesAssociadas({
      ...alteracoesAssociadas,
      [event.target.name]: event.target.checked,
    });
  };

  // Função para gerar o texto do laudo
  const gerarLaudo = () => {
    // Obter descrições corretas
    const formaDescricao =
      formaOptions.find((option) => option.value === forma)?.descricao || '';
    const orientacaoDescricao =
      orientacaoOptions.find((option) => option.value === orientacao)?.descricao || '';
    const margemDescricao =
      margemOptions.find((option) => option.value === margem)?.descricao || '';
    const ecogenicidadeDescricao =
      ecogenicidadeOptions.find((option) => option.value === ecogenicidade)
        ?.descricao || '';
    const efeitoPosteriorDescricao =
      efeitoPosteriorOptions.find((option) => option.value === efeitoPosterior)
        ?.descricao || '';

    // Inicia a descrição do nódulo
    let descricao = '';

    if (
      formaDescricao &&
      orientacaoDescricao &&
      margemDescricao &&
      ecogenicidadeDescricao &&
      efeitoPosteriorDescricao
    ) {
      descricao += `Nódulo de forma ${formaDescricao}, orientação ${orientacaoDescricao}, margens ${margemDescricao}, ${ecogenicidadeDescricao}, ${efeitoPosteriorDescricao}.`;
    }

    // Adiciona as alterações associadas (se houver)
    const associadas = [];
    if (alteracoesAssociadas.arquitetura) associadas.push('distorção da arquitetura');
    if (alteracoesAssociadas.ducto) associadas.push('alterações ductais');
    if (alteracoesAssociadas.pele) associadas.push('alterações cutâneas');
    if (alteracoesAssociadas.edema) associadas.push('edema');
    if (alteracoesAssociadas.elastografia) associadas.push('alteração na elastografia');

    if (associadas.length > 0) {
      descricao += ` Com alterações associadas: ${associadas.join(', ')}.`;
    }

    // Localização e medidas
    if (lado && relogio && distancia) {
      descricao += ` Localizado na mama ${lado.toLowerCase()}, às ${relogio} horas, a ${distancia} cm do mamilo`;
      if (medida1 && medida2 && medida3) {
        descricao += `, medindo ${medida1} x ${medida2} x ${medida3} cm.`;
      } else {
        descricao += '.';
      }
    } else if (medida1 && medida2 && medida3) {
      descricao += ` Medindo ${medida1} x ${medida2} x ${medida3} cm.`;
    }

    // Categoria BI-RADS
    if (categoria) {
      descricao += ` Classificado como BI-RADS ${categoria}.`;
    }

    return descricao.trim();
  };

  // Função para copiar o texto
  const copiarParaAreaDeTransferencia = () => {
    const textoLaudo = gerarLaudo();
    navigator.clipboard.writeText(textoLaudo);
    setCheckIconVisible(true);
    setTimeout(() => setCheckIconVisible(false), 2000);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Formatador de Texto BI-RADS Ultrassom
      </Typography>

      <Grid container spacing={3}>
        {/* Coluna Esquerda */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            {/* Forma */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Forma</InputLabel>
              <Select
                value={forma}
                onChange={(e) => setForma(e.target.value)}
                label="Forma"
              >
                {formaOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Orientação */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Orientação</InputLabel>
              <Select
                value={orientacao}
                onChange={(e) => setOrientacao(e.target.value)}
                label="Orientação"
              >
                {orientacaoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Margem */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Margens</InputLabel>
              <Select
                value={margem}
                onChange={(e) => setMargem(e.target.value)}
                label="Margens"
              >
                {margemOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Ecogenicidade */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Ecogenicidade</InputLabel>
              <Select
                value={ecogenicidade}
                onChange={(e) => setEcogenicidade(e.target.value)}
                label="Ecogenicidade"
              >
                {ecogenicidadeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Efeito Acústico Posterior */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Efeito Acústico Posterior</InputLabel>
              <Select
                value={efeitoPosterior}
                onChange={(e) => setEfeitoPosterior(e.target.value)}
                label="Efeito Acústico Posterior"
              >
                {efeitoPosteriorOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Alterações Associadas */}
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Alterações Associadas</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alteracoesAssociadas.arquitetura}
                    onChange={handleAlteracoesAssociadasChange}
                    name="arquitetura"
                  />
                }
                label="Distorção da arquitetura"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alteracoesAssociadas.ducto}
                    onChange={handleAlteracoesAssociadasChange}
                    name="ducto"
                  />
                }
                label="Alterações ductais"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alteracoesAssociadas.pele}
                    onChange={handleAlteracoesAssociadasChange}
                    name="pele"
                  />
                }
                label="Alterações cutâneas"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alteracoesAssociadas.edema}
                    onChange={handleAlteracoesAssociadasChange}
                    name="edema"
                  />
                }
                label="Edema"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alteracoesAssociadas.elastografia}
                    onChange={handleAlteracoesAssociadasChange}
                    name="elastografia"
                  />
                }
                label="Alteração na elastografia"
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Coluna Direita */}
        <Grid item xs={12} md={6}>
          {/* Localização */}
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <FormLabel component="legend">Localização</FormLabel>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Lado</InputLabel>
              <Select
                value={lado}
                onChange={(e) => setLado(e.target.value)}
                label="Lado"
              >
                <MenuItem value="Direita">Direita</MenuItem>
                <MenuItem value="Esquerda">Esquerda</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Posição (Horas)</InputLabel>
              <Select
                value={relogio}
                onChange={(e) => setRelogio(e.target.value)}
                label="Posição (Horas)"
              >
                {[...Array(12)].map((_, i) => (
                  <MenuItem key={i + 1} value={`${i + 1}`}>
                    {`${i + 1} horas`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Distância do mamilo (cm)"
              value={distancia}
              onChange={(e) => setDistancia(e.target.value)}
              fullWidth
            />
          </Paper>

          {/* Medidas */}
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">Medidas (cm):</Typography>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item xs={3}>
                <TextField
                  label="Medida 1"
                  value={medida1}
                  onChange={(e) => setMedida1(e.target.value)}
                />
              </Grid>
              <Grid item>x</Grid>
              <Grid item xs={3}>
                <TextField
                  label="Medida 2"
                  value={medida2}
                  onChange={(e) => setMedida2(e.target.value)}
                />
              </Grid>
              <Grid item>x</Grid>
              <Grid item xs={3}>
                <TextField
                  label="Medida 3"
                  value={medida3}
                  onChange={(e) => setMedida3(e.target.value)}
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Categoria BI-RADS */}
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Categoria BI-RADS</InputLabel>
              <Select
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
                label="Categoria BI-RADS"
              >
                <MenuItem value="0">0 - Avaliação Incompleta</MenuItem>
                <MenuItem value="1">1 - Negativo</MenuItem>
                <MenuItem value="2">2 - Achado Benigno</MenuItem>
                <MenuItem value="3">3 - Provavelmente Benigno</MenuItem>
                <MenuItem value="4A">4A - Baixa Suspeita</MenuItem>
                <MenuItem value="4B">4B - Suspeita Moderada</MenuItem>
                <MenuItem value="4C">4C - Alta Suspeita</MenuItem>
                <MenuItem value="5">5 - Altamente Suspeito</MenuItem>
                <MenuItem value="6">6 - Malignidade Confirmada</MenuItem>
              </Select>
            </FormControl>
          </Paper>

          {/* Texto Sugerido */}
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Texto Sugerido:</Typography>
            <TextField
              variant="outlined"
              multiline
              value={gerarLaudo()}
              rows={6}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={copiarParaAreaDeTransferencia}
              sx={{ mt: 1 }}
            >
              Copiar texto
            </Button>
            {checkIconVisible && <CheckIcon color="success" sx={{ ml: 1 }} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BiradsUltrassom;
