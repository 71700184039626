import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { LinkedIn, Twitter, Facebook, Instagram, Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import logo from '../assets/logo2.png';

function Header() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const socialLinks = (
    <List>
      <ListItem button component="a" href="https://www.linkedin.com" target="_blank">
        <ListItemIcon>
          <LinkedIn />
        </ListItemIcon>
        <ListItemText primary="LinkedIn" />
      </ListItem>
      <ListItem button component="a" href="https://twitter.com" target="_blank">
        <ListItemIcon>
          <Twitter />
        </ListItemIcon>
        <ListItemText primary="Twitter" />
      </ListItem>
      <ListItem button component="a" href="https://www.facebook.com" target="_blank">
        <ListItemIcon>
          <Facebook />
        </ListItemIcon>
        <ListItemText primary="Facebook" />
      </ListItem>
      <ListItem button component="a" href="https://www.instagram.com" target="_blank">
        <ListItemIcon>
          <Instagram />
        </ListItemIcon>
        <ListItemText primary="Instagram" />
      </ListItem>
    </List>
  );

  return (
    <AppBar
      position="relative"
      sx={{ 
        backgroundColor: '#f5f5f5', 
        boxShadow: 'none',
        zIndex: (theme) => theme.zIndex.drawer - 1,
        paddingX: 4,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', height: '120px' }}>
        
        {/* Left side - Logo and Title */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={logo} 
            alt="Logo" 
            style={{ height: 100, width: 'auto', marginRight: 24 }} 
          />
          <Typography
            variant="h3"
            color="text.primary"
            sx={{
              fontWeight: 'bold',
              letterSpacing: 1.2,
            }}
          >
            Central Radiologia
          </Typography>
        </Box>

        {/* Right side - Social Media or Menu Icon */}
        {!isSmallScreen ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              href="https://www.linkedin.com/company/central-radiologia/"
              target="_blank"
              sx={{ color: '#0077b5', marginLeft: 1 }}
            >
              <LinkedIn fontSize="large" />
            </IconButton>
            {/* <IconButton
              href="https://twitter.com"
              target="_blank"
              sx={{ color: '#1da1f2', marginLeft: 1 }}
            >
              <Twitter fontSize="large" />
            </IconButton> */}
            {/* <IconButton
              href="https://www.facebook.com"
              target="_blank"
              sx={{ color: '#1877f2', marginLeft: 1 }}
            >
              <Facebook fontSize="large" />
            </IconButton> */}
            {/* <IconButton
              href="https://www.instagram.com"
              target="_blank"
              sx={{ color: '#E4405F', marginLeft: 1 }}
            >
              <Instagram fontSize="large" />
            </IconButton> */}
          </Box>
        ) : (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(!drawerOpen)}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
      </Toolbar>

      {/* Drawer for Small Screens */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {socialLinks}
      </Drawer>
    </AppBar>
  );
}

export default Header;
