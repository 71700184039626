import React, { useState } from 'react';
import {
    TextField,
    Typography,
    Button,
    Box,
    IconButton,
    Tooltip,
    Collapse,
    Link,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function VolumeCalculator() {
    const [inputValue, setInputValue] = useState('');
    const [outputText, setOutputText] = useState('');
    const [copied, setCopied] = useState(false);
    const [showReference, setShowReference] = useState(false);

    const handleCalculate = () => {
        // Regex para extrair os três valores numéricos e opcionalmente "cm" no final
        const regex = /^\s*(\d+(?:,\d+)?)\s*x\s*(\d+(?:,\d+)?)\s*x\s*(\d+(?:,\d+)?)\s*(?:cm)?\s*$/i;
        const matches = inputValue.match(regex);

        if (!matches || matches.length < 4) {
            setOutputText('Formato inválido. Use o padrão: "A x B x C cm" ou "A x B x C"');
            return;
        }

        // Extrair os três valores
        const [, valD1, valD2, valD3] = matches;

        // Converter vírgulas para pontos e transformar em número
        const D1 = parseFloat(valD1.replace(',', '.'));
        const D2 = parseFloat(valD2.replace(',', '.'));
        const D3 = parseFloat(valD3.replace(',', '.'));

        if (isNaN(D1) || isNaN(D2) || isNaN(D3)) {
            setOutputText('Valores numéricos inválidos.');
            return;
        }

        // Calcular volume usando a fórmula simplificada do elipsoide
        const volume = 0.52 * D1 * D2 * D3; // resultado em número com ponto

        // Formatar volume com uma casa decimal e substituir ponto por vírgula
        const volumeFormatado = volume.toFixed(1).replace('.', ',');

        // Formatar os valores com vírgula decimal e uma casa decimal
        const D1Formatted = D1.toFixed(1).replace('.', ',');
        const D2Formatted = D2.toFixed(1).replace('.', ',');
        const D3Formatted = D3.toFixed(1).replace('.', ',');

        // Gerar texto final no formato "A x B x C cm (volume estimado de X cm³)"
        const textoFinal = `${D1Formatted} x ${D2Formatted} x ${D3Formatted} cm (volume estimado de ${volumeFormatado} cm³)`;
        setOutputText(textoFinal);
    };

    const handleCopy = () => {
        if (outputText) {
            navigator.clipboard
                .writeText(outputText)
                .then(() => {
                    setCopied(true);
                    // Resetar o estado após 2 segundos
                    setTimeout(() => {
                        setCopied(false);
                    }, 2000);
                })
                .catch((err) => {
                    console.error('Falha ao copiar o texto: ', err);
                });
        }
    };

    const toggleReference = () => {
        setShowReference((prev) => !prev);
    };

    return (
        <Box sx={{ padding: 4, maxWidth: 600, margin: '0 auto' }}>
            {/* Título do Componente */}
            <Typography variant="h5" gutterBottom>
                Calculadora de Volume
            </Typography>

            {/* Campo de Entrada para as Medidas */}
            <TextField
                fullWidth
                variant="outlined"
                label='Digite as medidas no formato "A x B x C cm" ou "A x B x C"'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                sx={{ marginBottom: 2 }}
            />

            {/* Botão para Calcular o Volume */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleCalculate}
                sx={{ marginBottom: 2 }}
            >
                Calcular
            </Button>

            {/* Exibição do Resultado e Botão de Cópia */}
            {outputText && (
                <Box>
                    {/* Campo de Texto para Exibir o Resultado */}
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={outputText}
                        InputProps={{
                            readOnly: true, // Torna o campo somente leitura
                        }}
                        sx={{ marginBottom: 2 }}
                    />

                    {/* Botão para Copiar o Resultado para a Área de Transferência */}
                    <Tooltip title={copied ? 'Copiado!' : 'Copiar para clipboard'}>
                        <IconButton
                            onClick={handleCopy}
                            color={copied ? 'success' : 'primary'}
                            sx={{
                                transition: 'color 0.3s, background-color 0.3s',
                                backgroundColor: copied
                                    ? 'rgba(76, 175, 80, 0.1)'
                                    : 'transparent',
                                '&:hover': {
                                    backgroundColor: copied
                                        ? 'rgba(76, 175, 80, 0.2)'
                                        : 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            )}

            {/* Botão para Mostrar/Ocultar a Referência */}
            <Box sx={{ marginTop: 4 }}>
                <Button
                    variant="text"
                    startIcon={showReference ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={toggleReference}
                >
                    {showReference ? 'Ocultar Referência' : 'Mostrar Referência'}
                </Button>

                {/* Seção de Referência Colapsável */}
                <Collapse in={showReference}>
                    <Box sx={{ marginTop: 2, padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1 }}>
                        <Typography variant="h6" gutterBottom>
                            Referência do Cálculo
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Na prática clínica, especialmente em radiologia, a fórmula do volume do elipsoide é frequentemente adaptada para facilitar os cálculos utilizando os diâmetros medidos nos exames de imagem.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Os diâmetros \( D_1 \), \( D_2 \) e \( D_3 \) correspondem ao dobro dos semi-eixos \( a \), \( b \) e \( c \) do elipsoide, respectivamente:
                        </Typography>
                        <Typography variant="body2" component="div" paragraph>
                            <strong>D₁ = 2a</strong>, <strong>D₂ = 2b</strong> e <strong>D₃ = 2c</strong>.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Substituindo esses valores na fórmula original do volume do elipsoide:
                        </Typography>
                        <Typography variant="body2" component="div" paragraph>
                            <strong>V = (4/3) × π × (D₁/2) × (D₂/2) × (D₃/2)</strong>
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Simplificando a expressão:
                        </Typography>
                        <Typography variant="body2" component="div" paragraph>
                            <strong>V = (π/6) × D₁ × D₂ × D₃</strong>
                        </Typography>
                        <Typography variant="body2" paragraph>
                            O fator \( \pi/6 \) é aproximadamente igual a **0,523**. Para facilitar ainda mais os cálculos na prática clínica, esse valor é arredondado para **0,52**, resultando na fórmula simplificada:
                        </Typography>
                        <Typography variant="body2" component="div" paragraph>
                            <strong>V ≈ 0,52 × D₁ × D₂ × D₃</strong>
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Essa aproximação é amplamente utilizada em radiologia para estimar volumes.
                        </Typography>

                    </Box>
                </Collapse>
            </Box>
        </Box>
    );
}

export default VolumeCalculator;
