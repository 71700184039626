import React from 'react';
import { Typography, Container } from '@mui/material';

function Laudos() {
  return (
    <Container sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h4">Laudos</Typography>
    </Container>
  );
}

export default Laudos;
