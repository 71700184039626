import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

function Menubar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="sticky"
        color="primary"
        sx={{ backgroundColor: '#192936', paddingY: '4px', zIndex: (theme) => theme.zIndex.drawer }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', minHeight: '48px' }}>
          
          {/* Drawer for Mobile View */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
          {/* Drawer content for Mobile View */}
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List sx={{ width: 250 }}>
              {/* Single "Ferramentas" item in Drawer */}
              <ListItem button component={NavLink} to="/ferramentas" onClick={toggleDrawer(false)}>
                <ListItemText primary="Ferramentas" />
              </ListItem>
              {/* "Máscaras" item in Drawer */}
              <ListItem button component={NavLink} to="/mascaras" onClick={toggleDrawer(false)}>
                <ListItemText primary="Máscaras de relatórios" />
              </ListItem>
              {/* External Link for "Contato" */}
              <ListItem
                button
                component="a"
                href="https://forms.gle/HWPpzAGgyi8tu7PY8"
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Contato" />
              </ListItem>
            </List>
          </Drawer>

          {/* Horizontal Menu for Desktop View */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}>
            <Button color="inherit" component={NavLink} to="/" sx={{ fontWeight: 'bold' }}>
              Início
            </Button>
            {/* Single button for "Ferramentas" */}
            <Button
              color="inherit"
              component={NavLink}
              to="/ferramentas"
              sx={{ fontWeight: 'bold' }}
            >
              Ferramentas
            </Button>
            {/* "Máscaras" button for Desktop Menu */}
            <Button color="inherit" component={NavLink} to="/mascaras" sx={{ fontWeight: 'bold' }}>
              Máscaras de relatórios
            </Button>
            <Button color="inherit" component={NavLink} to="/ferramentas/speech2text" sx={{ fontWeight: 'bold' }}>
              Voz para Texto
            </Button>
            {/* External link for "Contato" */}
            <Button
              color="inherit"
              href="https://forms.gle/HWPpzAGgyi8tu7PY8"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontWeight: 'bold' }}
            >
              Contato
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Menubar;
