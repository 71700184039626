import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

// Correctly importing images based on your directory structure
import heroImage from '../../assets/hero_image.png';
import toolsImage from '../../assets/toolsImage.png';
import masksImage from '../../assets/masksImage.png';
import educationImage from '../../assets/educationImage.png';
import featureImage from '../../assets/feature_image2.png';

function Inicio() {
  const navigate = useNavigate();

  // Sections of the site
  const sections = [
    {
      path: '/ferramentas',
      image: toolsImage,
      title: 'Ferramentas',
      description:
        'Acesse nossas calculadoras e ferramentas avançadas para auxiliar no diagnóstico e laudos radiológicos.',
    },
    {
      path: '/mascaras',
      image: masksImage,
      title: 'Máscaras de Laudos',
      description:
        'Encontre modelos de laudos personalizados para agilizar sua rotina e garantir a padronização dos seus relatórios.',
    },
    {
      path: '/educacao',
      image: educationImage,
      title: 'Educação Continuada',
      description:
        'Atualize-se com os últimos artigos, cursos e materiais educativos em radiologia.',
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          color: 'white',
          py: { xs: 6, md: 8 },
          backgroundImage: `url(${heroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay to improve text readability */}
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          }}
        ></Box>
        <Container
          maxWidth="lg"
          sx={{ position: 'relative', zIndex: 1, textAlign: 'left' }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography variant="h2" component="h1" gutterBottom>
                Bem-vindo ao Central Radiologia
              </Typography>
              <Typography variant="h5" component="p" gutterBottom>
                Seu portal completo para ferramentas, máscaras de laudos e recursos educacionais em radiologia.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => navigate('/ferramentas')}
                sx={{ mt: 4, mr: 2 }}
                endIcon={<ArrowForward />}
              >
                Ferramentas
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => navigate('/mascaras')}
                sx={{ mt: 4, color: 'white', borderColor: 'white' }}
              >
                Máscaras de Laudos
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Main Sections */}
      <Container sx={{ py: 8 }} maxWidth="lg">
        <Typography variant="h4" component="h2" gutterBottom align="center">
          O que oferecemos
        </Typography>
        <Grid container spacing={4}>
          {sections.map((section) => (
            <Grid item xs={12} sm={6} md={4} key={section.title}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 3,
                  '&:hover': { boxShadow: 6 },
                }}
              >
                <CardActionArea
                  onClick={() => navigate(section.path)}
                  sx={{ flexGrow: 1 }}
                >
                  <CardMedia
                    component="img"
                    alt={section.title}
                    height="180"
                    image={section.image}
                    title={section.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h3">
                      {section.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {section.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Feature Section */}
      <Box sx={{ backgroundColor: 'background.default', py: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h2" gutterBottom>
                Inovação e Tecnologia
              </Typography>
              <Typography variant="body1" paragraph>
                Utilizamos as últimas tecnologias para desenvolver ferramentas que otimizam o fluxo de trabalho e melhoram a precisão diagnóstica, permitindo que você se concentre no que realmente importa: o paciente.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate('/ferramentas')}
                endIcon={<ArrowForward />}
              >
                Conheça Nossas Ferramentas
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={featureImage}
                alt="Tecnologia avançada"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      
      {/* Newsletter Section */}
      

      {/* Contact Section */}
      <Box sx={{ backgroundColor: 'primary.main', color: 'white', py: 8 }}>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Fale Conosco
          </Typography>
          <Typography variant="body1" paragraph align="center">
            Tem alguma dúvida, sugestão ou deseja colaborar conosco? Entre em contato!
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              href="mailto:julio.nather@gmail.com"
              endIcon={<ArrowForward />}
            >
              Enviar Mensagem
            </Button>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default Inicio;
