import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.grey[800],
        color: 'white',
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
        <Typography variant="body2">
          Central Radiologia © {new Date().getFullYear()}
        </Typography>
        <Link href="/terms" variant="body2" underline="hover" color="inherit">
          Termos de Serviço
        </Link>
      </Container>
    </Box>
  );
}

export default Footer;
