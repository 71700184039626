import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  TextField,
  Box,
  Chip,
  Container,
} from '@mui/material';

// Correctly importing images based on your directory structure
import tiradsImage from '../../assets/tireoide.PNG';
import rmFerroImage from '../../assets/IronMRI.jpg';
import boneAgeImage from '../../assets/bone_age.jpeg';
import oradsImage from '../../assets/orads.PNG';
import liverFatImage from '../../assets/TC_gordura_hepatica.png';
import breastUltrasoundImage from '../../assets/breast_ultrasound.png';
import volumeCalculatorImage from '../../assets/volumecalculator.png';


function Ferramentas() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const ferramentas = [
    {
      path: '/ferramentas/tirads',
      image: tiradsImage,
      title: 'Calculadora ACR TI-RADS',
      description:
        'Classifique nódulos de tireoide com base nos critérios ultrassonográficos do ACR TI-RADS e gera relatórios detalhados.',
      category: 'Ultrassom',
    },
    {
      path: '/ferramentas/rmferro',
      image: rmFerroImage,
      title: 'Quantificação de Ferro Hepático',
      description:
        'Calcula a concentração de ferro no fígado utilizando métodos avançados de relaxometria R2* por ressonância magnética.',
      category: 'Ressonância Magnética',
    },
    {
      path: '/ferramentas/idadeossea',
      image: boneAgeImage,
      title: 'Apoio a laudo de Idade Óssea',
      description:
        'Ajuda a laudar radiografias para idade esquelética de crianças e adolescentes através do método de Greulich e Pyle.',
      category: 'Radiografia',
    },
    {
      path: '/ferramentas/oradsrm',
      image: oradsImage,
      title: 'Calculadora O-RADS RM',
      description:
        'Avalia lesões ovarianas utilizando critérios do O-RADS RM para determinar o risco de malignidade.',
      category: 'Ressonância Magnética',
    },
    {
      path: '/ferramentas/fracaogorduract',
      image: liverFatImage,
      title: 'Fração de Gordura Hepática',
      description:
        'Calcula a fração de gordura hepática e gera descrições para seus laudos.',
      category: 'Tomografia Computadorizada',
    },
    {
      path: '/ferramentas/birads-ultrassom',
      image: breastUltrasoundImage,
      title: 'Ferramenta BI-RADS Ultrassom',
      description:
        'Formatar relatórios de nódulos mamários com base nos critérios do BI-RADS para ultrassom.',
      category: 'Ultrassom',
    },
    ,
    {
      path: '/ferramentas/volumecalculator',
      image: volumeCalculatorImage, // Substitua pelo caminho da imagem correta
      title: 'Calculadora de Volumes',
      description:
        'Calcula volumes automaticamente a partir de imagens médicas, gerando dados precisos para análises e relatórios.',
      category: 'Geral',
    }
    // Add more tools here if necessary
  ];

  const categories = [...new Set(ferramentas.map((ferramenta) => ferramenta.category))];

  const filteredFerramentas = ferramentas.filter((ferramenta) => {
    const matchesSearch =
      ferramenta.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ferramenta.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ferramenta.category.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCategory =
      selectedCategories.length === 0 || selectedCategories.includes(ferramenta.category);

    return matchesSearch && matchesCategory;
  });

  return (
    <Container sx={{ py: 2 }} maxWidth="lg">
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Ferramentas Disponíveis
      </Typography>

      {/* Search Field */}
      <Box sx={{ mb: 4, mt: 4 }}>
        <TextField
          label="Pesquisar Ferramentas"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      {/* Category Filter */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Filtrar por Modalidade:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {categories.map((category) => (
            <Chip
              key={category}
              label={category}
              clickable
              color={selectedCategories.includes(category) ? 'primary' : 'default'}
              onClick={() => {
                if (selectedCategories.includes(category)) {
                  setSelectedCategories(selectedCategories.filter((c) => c !== category));
                } else {
                  setSelectedCategories([...selectedCategories, category]);
                }
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Tools List */}
      <Grid container spacing={4}>
        {filteredFerramentas.map((ferramenta) => (
          <Grid item xs={12} sm={6} md={4} key={ferramenta.title}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                '&:hover': { boxShadow: 6 },
              }}
            >
              <CardActionArea
                onClick={() => navigate(ferramenta.path)}
                sx={{ flexGrow: 1 }}
              >
                <CardMedia
                  component="img"
                  alt={ferramenta.title}
                  height="180"
                  image={ferramenta.image}
                  title={ferramenta.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h3">
                    {ferramenta.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    {ferramenta.description}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Modalidade: {ferramenta.category}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        {filteredFerramentas.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Nenhuma ferramenta encontrada.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Ferramentas;
