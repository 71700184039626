import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Typography, Grid, Paper, TextField } from "@mui/material";

// Create MUI theme for spacing
const theme = createTheme();

export default function CcLSCalculator() {
  const [state, setState] = useState({
    macroFat: 0,
    solidMass: 0,
    t2Int: 0,
    cmEnh: 0,
    sei: 0,
    dwi: 0,
    adernVal: 0,
    adernState: 0,
    micFatVal: 0,
    micFatPresent: 0,
    upgMicFatVal: 0,
    upgMicFatPresent: 0,
    classification: "",
    ccLSScore: "",
    reportText: "",
    conclusionText: "",
  });

  // Constants for ccLS scores
  const ccLS5 = "5 - Very Likely clear cell renal cell carcinoma";
  const ccLS4 = "4 - Likely clear cell renal cell carcinoma";
  const ccLS3 = "3 - Intermediate likelihood clear cell renal cell carcinoma";
  const ccLS2 = "2 - Unlikely clear cell renal cell carcinoma";
  const ccLS1 = "1 - Very Unlikely clear cell renal cell carcinoma";

  // Function to handle Macroscopic Fat selection
  const handleMacFat = (newVal) => {
    if (state.macroFat === newVal) {
      resetMacFat(0);
    } else {
      resetMacFat(newVal);
      switch (newVal) {
        case 1:
          // Yes - Macroscopic Fat Present
          setState((prevState) => ({
            ...prevState,
            macroFat: newVal,
            // Show NoccLS and cAML result
            classification: "It is likely a classic angiomyolipoma (AML)",
            ccLSScore: ccLS1,
          }));
          break;
        case 2:
          // No - Macroscopic Fat Absent
          setState((prevState) => ({
            ...prevState,
            macroFat: newVal,
          }));
          break;
        default:
          break;
      }
    }
  };

  const resetMacFat = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      macroFat: newVal,
      solidMass: 0,
      classification: "",
      ccLSScore: "",
      t2Int: 0,
      cmEnh: 0,
      sei: 0,
      dwi: 0,
      adernVal: 0,
      adernState: 0,
      micFatVal: 0,
      micFatPresent: 0,
      upgMicFatVal: 0,
      upgMicFatPresent: 0,
    }));
  };

  // Function to handle Solid Mass selection
  const handleSolidMass = (newVal) => {
    if (state.solidMass === newVal) {
      resetSolidMass(0);
    } else {
      resetSolidMass(newVal);
      switch (newVal) {
        case 1:
          // Yes - Solid Mass
          setState((prevState) => ({
            ...prevState,
            solidMass: newVal,
          }));
          break;
        case 2:
          // No - Not Solid Mass
          setState((prevState) => ({
            ...prevState,
            solidMass: newVal,
            // Show NoccLS and Bosniak result
            classification: "Use the Bosniak classification for cystic masses",
            ccLSScore: ccLS1,
          }));
          break;
        default:
          break;
      }
    }
  };

  const resetSolidMass = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      solidMass: newVal,
      t2Int: 0,
      cmEnh: 0,
      sei: 0,
      dwi: 0,
      adernVal: 0,
      adernState: 0,
      micFatVal: 0,
      micFatPresent: 0,
      upgMicFatVal: 0,
      upgMicFatPresent: 0,
      classification: "",
      ccLSScore: "",
    }));
  };

  // Function to handle T2 Signal Intensity selection
  const handleT2Int = (newVal) => {
    if (state.t2Int === newVal) {
      resetT2Int(0);
    } else {
      resetT2Int(newVal);
      setState((prevState) => ({
        ...prevState,
        t2Int: newVal,
      }));
    }
  };

  const resetT2Int = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      t2Int: newVal,
      cmEnh: 0,
      sei: 0,
      dwi: 0,
      adernVal: 0,
      adernState: 0,
      micFatVal: 0,
      micFatPresent: 0,
      upgMicFatVal: 0,
      upgMicFatPresent: 0,
      classification: "",
      ccLSScore: "",
    }));
  };

  // Function to handle Corticomedullary Enhancement selection
  const handleCMEnh = (newVal) => {
    if (state.cmEnh === newVal) {
      resetCMEnh(0);
    } else {
      resetCMEnh(newVal);
      setState((prevState) => ({
        ...prevState,
        cmEnh: newVal,
      }));
      switch_CM_Enh(newVal);
    }
  };

  const resetCMEnh = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      cmEnh: newVal,
      sei: 0,
      dwi: 0,
      adernVal: 0,
      adernState: 0,
      micFatVal: 0,
      micFatPresent: 0,
      upgMicFatVal: 0,
      upgMicFatPresent: 0,
      classification: "",
      ccLSScore: "",
    }));
  };

  const switch_CM_Enh = (newVal) => {
    const { t2Int } = state;
    if (newVal === 1) {
      // Intense Enhancement
      if (t2Int === 1 || t2Int === 2) {
        // Show Microscopic Fat options
        setState((prevState) => ({
          ...prevState,
          showMicFat: true,
        }));
      } else if (t2Int === 3) {
        // Show ADER options
        setState((prevState) => ({
          ...prevState,
          showADER: true,
        }));
      }
    } else if (newVal === 2) {
      // Moderate Enhancement
      if (t2Int === 1 || t2Int === 2) {
        // Show SEI options
        setState((prevState) => ({
          ...prevState,
          showSEI: true,
        }));
      } else if (t2Int === 3) {
        // Set ccLS to 3
        setState((prevState) => ({
          ...prevState,
          classification: ccLS3,
        }));
      }
    } else if (newVal === 3) {
      // Mild Enhancement
      if (t2Int === 1) {
        // Set ccLS to 3
        setState((prevState) => ({
          ...prevState,
          classification: ccLS3,
        }));
      } else if (t2Int === 2) {
        // Show DWI options
        setState((prevState) => ({
          ...prevState,
          showDWI: true,
        }));
      } else if (t2Int === 3) {
        // Show Upgraded Microscopic Fat options
        setState((prevState) => ({
          ...prevState,
          showUpgMicFat: true,
        }));
      }
    }
  };

  // Function to handle Microscopic Fat selection
  const handleMicFat = (newVal) => {
    if (state.micFatVal === newVal) {
      resetMicFat(0);
    } else {
      resetMicFat(newVal);
      setState((prevState) => ({
        ...prevState,
        micFatVal: newVal,
      }));
      switch_MicFat(newVal);
    }
  };

  const resetMicFat = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      micFatVal: newVal,
      sei: 0,
      classification: "",
      ccLSScore: "",
    }));
  };

  const switch_MicFat = (newVal) => {
    if (newVal === 1) {
      // Yes - Microscopic Fat Present
      setState((prevState) => ({
        ...prevState,
        classification: ccLS5,
      }));
    } else if (newVal === 2) {
      // No - Microscopic Fat Absent
      setState((prevState) => ({
        ...prevState,
        showSEI: true,
      }));
    }
  };

  // Function to handle SEI selection
  const handleSEI = (newVal) => {
    if (state.sei === newVal) {
      resetSEI(0);
    } else {
      resetSEI(newVal);
      setState((prevState) => ({
        ...prevState,
        sei: newVal,
      }));
      switch_SEI(newVal);
    }
  };

  const resetSEI = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      sei: newVal,
      classification: "",
      ccLSScore: "",
    }));
  };

  const switch_SEI = (newVal) => {
    const { cmEnh } = state;
    if (newVal === 1) {
      // Yes - SEI Present
      if (cmEnh === 1) {
        setState((prevState) => ({
          ...prevState,
          classification: ccLS3,
        }));
      } else if (cmEnh === 2) {
        // Show Upgraded Microscopic Fat options
        setState((prevState) => ({
          ...prevState,
          showUpgMicFat: true,
        }));
      }
    } else if (newVal === 2) {
      // No - SEI Absent
      if (cmEnh === 1) {
        setState((prevState) => ({
          ...prevState,
          classification: ccLS4,
        }));
      } else if (cmEnh === 2) {
        setState((prevState) => ({
          ...prevState,
          classification: ccLS3,
        }));
      }
    }
  };

  // Function to handle DWI selection
  const handleDWI = (newVal) => {
    if (state.dwi === newVal) {
      resetDWI(0);
    } else {
      resetDWI(newVal);
      setState((prevState) => ({
        ...prevState,
        dwi: newVal,
      }));
      switch_DWI(newVal);
    }
  };

  const resetDWI = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      dwi: newVal,
      classification: "",
      ccLSScore: "",
    }));
  };

  const switch_DWI = (newVal) => {
    const { cmEnh, adernState } = state;
    if (cmEnh === 3) {
      // Show Upgraded Microscopic Fat options
      setState((prevState) => ({
        ...prevState,
        showUpgMicFat: true,
      }));
    } else {
      if (adernState === 1) {
        setState((prevState) => ({
          ...prevState,
          classification: newVal === 1 ? ccLS2 : ccLS3,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          classification: newVal === 1 ? ccLS3 : ccLS4,
        }));
      }
    }
  };

  // Function to handle Upgraded Microscopic Fat selection
  const handleUpgMicFat = (newVal) => {
    if (state.upgMicFatVal === newVal) {
      resetUpgMicFat(0);
    } else {
      resetUpgMicFat(newVal);
      setState((prevState) => ({
        ...prevState,
        upgMicFatVal: newVal,
      }));
      switch_UpgMicFat(newVal);
    }
  };

  const resetUpgMicFat = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      upgMicFatVal: newVal,
      classification: "",
      ccLSScore: "",
    }));
  };

  const switch_UpgMicFat = (newVal) => {
    const { t2Int, sei, cmEnh, dwi } = state;
    if (newVal === 1) {
      // Yes - Upgraded Microscopic Fat Present
      setState((prevState) => ({
        ...prevState,
        classification: ccLS3,
      }));
    } else if (newVal === 2) {
      // No - Upgraded Microscopic Fat Absent
      if (t2Int === 1) {
        setState((prevState) => ({
          ...prevState,
          classification: sei === 1 ? ccLS2 : ccLS3,
        }));
      } else if (t2Int === 2) {
        if (cmEnh === 2) {
          setState((prevState) => ({
            ...prevState,
            classification: sei === 1 ? ccLS2 : ccLS3,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            classification: dwi === 1 ? ccLS1 : ccLS2,
          }));
        }
      } else if (t2Int === 3) {
        setState((prevState) => ({
          ...prevState,
          classification: ccLS1,
        }));
      }
    }
  };

  // Function to handle ADER selection
  const handleADER = (newVal) => {
    if (state.adernState === newVal) {
      resetADER(0);
    } else {
      resetADER(newVal);
      setState((prevState) => ({
        ...prevState,
        adernState: newVal,
      }));
      switch_ADER(newVal);
    }
  };

  const resetADER = (newVal) => {
    setState((prevState) => ({
      ...prevState,
      adernState: newVal,
      dwi: 0,
      classification: "",
      ccLSScore: "",
    }));
  };

  const switch_ADER = (newVal) => {
    // Show DWI options
    setState((prevState) => ({
      ...prevState,
      showDWI: true,
    }));
  };

  // Reset the entire calculator
  const resetCalculator = () => {
    setState({
      macroFat: 0,
      solidMass: 0,
      t2Int: 0,
      cmEnh: 0,
      sei: 0,
      dwi: 0,
      adernVal: 0,
      adernState: 0,
      micFatVal: 0,
      micFatPresent: 0,
      upgMicFatVal: 0,
      upgMicFatPresent: 0,
      classification: "",
      ccLSScore: "",
      reportText: "",
      conclusionText: "",
      showMicFat: false,
      showSEI: false,
      showDWI: false,
      showADER: false,
      showUpgMicFat: false,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: theme.spacing(2) }}>
        <Typography variant="h4" gutterBottom align="center">
          ccLS Calculator
        </Typography>

        {/** Step 1: Macroscopic Fat */}
        <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          <Typography variant="h6">Is there macroscopic fat?</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={state.macroFat === 1 ? "contained" : "outlined"}
                onClick={() => handleMacFat(1)}
                fullWidth
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={state.macroFat === 2 ? "contained" : "outlined"}
                onClick={() => handleMacFat(2)}
                fullWidth
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {/** Show result if Macroscopic Fat is Yes */}
        {state.macroFat === 1 && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">You should not use ccLS for this mass.</Typography>
            <Typography variant="h6">It is likely a classic angiomyolipoma (AML).</Typography>
          </Paper>
        )}

        {/** Step 2: Solid Mass */}
        {state.macroFat === 2 && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">Is it a solid mass? (&gt;25% enhancing component)</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.solidMass === 1 ? "contained" : "outlined"}
                  onClick={() => handleSolidMass(1)}
                  fullWidth
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.solidMass === 2 ? "contained" : "outlined"}
                  onClick={() => handleSolidMass(2)}
                  fullWidth
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** Show result if Not Solid Mass */}
        {state.solidMass === 2 && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">You should not use ccLS for this mass.</Typography>
            <Typography variant="h6">
              Use the{" "}
              <a href="https://bosniak-calculator.herokuapp.com/">Bosniak classification</a> for cystic masses.
            </Typography>
          </Paper>
        )}

        {/** Step 3: T2 Signal Intensity */}
        {state.solidMass === 1 && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">Mass T2 Signal Intensity Relative to Renal Cortex</Typography>
            <Typography variant="body2">Assess T2 signal ONLY in enhancing component of tumor</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant={state.t2Int === 1 ? "contained" : "outlined"}
                  onClick={() => handleT2Int(1)}
                  fullWidth
                >
                  Hyperintense
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant={state.t2Int === 2 ? "contained" : "outlined"}
                  onClick={() => handleT2Int(2)}
                  fullWidth
                >
                  Isointense
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant={state.t2Int === 3 ? "contained" : "outlined"}
                  onClick={() => handleT2Int(3)}
                  fullWidth
                >
                  Hypointense
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** Step 4: Corticomedullary Enhancement */}
        {state.t2Int !== 0 && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">Enhancement on Corticomedullary (CM) Phase</Typography>
            <Typography variant="body2">
              Assess area with the <em>HIGHEST</em> enhancement on CM phase. Compare between phases using identically
              acquired images.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant={state.cmEnh === 1 ? "contained" : "outlined"}
                  onClick={() => handleCMEnh(1)}
                  fullWidth
                >
                  Intense
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant={state.cmEnh === 2 ? "contained" : "outlined"}
                  onClick={() => handleCMEnh(2)}
                  fullWidth
                >
                  Moderate
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant={state.cmEnh === 3 ? "contained" : "outlined"}
                  onClick={() => handleCMEnh(3)}
                  fullWidth
                >
                  Mild
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** Microscopic Fat Options */}
        {state.showMicFat && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">Is Microscopic Fat Present?</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.micFatVal === 1 ? "contained" : "outlined"}
                  onClick={() => handleMicFat(1)}
                  fullWidth
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.micFatVal === 2 ? "contained" : "outlined"}
                  onClick={() => handleMicFat(2)}
                  fullWidth
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** SEI Options */}
        {state.showSEI && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">Is Segmental Enhancement Inversion (SEI) Present?</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.sei === 1 ? "contained" : "outlined"}
                  onClick={() => handleSEI(1)}
                  fullWidth
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.sei === 2 ? "contained" : "outlined"}
                  onClick={() => handleSEI(2)}
                  fullWidth
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** DWI Options */}
        {state.showDWI && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">
              Is the tumor homogeneous or shows marked restriction on DWI (Diffusion-Weighted Imaging)?
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.dwi === 1 ? "contained" : "outlined"}
                  onClick={() => handleDWI(1)}
                  fullWidth
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.dwi === 2 ? "contained" : "outlined"}
                  onClick={() => handleDWI(2)}
                  fullWidth
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** Upgraded Microscopic Fat Options */}
        {state.showUpgMicFat && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">Is Unequivocal Microscopic Fat Present?</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.upgMicFatVal === 1 ? "contained" : "outlined"}
                  onClick={() => handleUpgMicFat(1)}
                  fullWidth
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={state.upgMicFatVal === 2 ? "contained" : "outlined"}
                  onClick={() => handleUpgMicFat(2)}
                  fullWidth
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/** Show Final Classification */}
        {state.classification && (
          <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography variant="h6">The calculated ccLS is:</Typography>
            <Typography variant="h5">{state.classification}</Typography>
          </Paper>
        )}

        {/** Reset Button */}
        <div style={{ textAlign: "center", marginTop: theme.spacing(4) }}>
          <Button variant="outlined" color="secondary" onClick={resetCalculator}>
            Reset
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}
