import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, // Added import
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

// Define the base API URL (make sure to set it as needed)
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://www.centralradiologia.com.br/api";

export default function Mascaras() {
  const [modalidades, setModalidades] = useState([]);
  const [regioes, setRegioes] = useState([]);
  const [miscelanias, setMiscelanias] = useState([]);
  const [laudos, setLaudos] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [selectedModalidade, setSelectedModalidade] = useState("");
  const [selectedRegiao, setSelectedRegiao] = useState("");
  const [selectedMiscelania, setSelectedMiscelania] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  const [selectedLaudo, setSelectedLaudo] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const muiTheme = useTheme();

  // Fetch laudos based on filters and search query
  useEffect(() => {
    const fetchLaudos = () => {
      axios
        .get(`${API_BASE_URL}/laudos/filter`, {
          params: {
            modalidade_id: selectedModalidade,
            regiao_corpo_id: selectedRegiao,
            miscelania_id: selectedMiscelania,
            search_query: searchQuery,
          },
        })
        .then((response) => {
          setLaudos(response.data);
          setHasMore(false); // Disable pagination when filtering or searching
        })
        .catch((err) => {
          console.error("Error fetching filtered laudos:", err);
        });
    };

    fetchLaudos();
  }, [selectedModalidade, selectedRegiao, selectedMiscelania, searchQuery]);

  // Fetch modalidades on component mount
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/modalidades`)
      .then((response) => {
        setModalidades(response.data);
      })
      .catch((err) => {
        console.error("Error fetching modalidades:", err);
      });
  }, []);

  // Fetch regioes when modalidade is selected
  useEffect(() => {
    if (selectedModalidade) {
      axios
        .get(`${API_BASE_URL}/regioes-corpo`)
        .then((response) => {
          setRegioes(response.data);
        })
        .catch((err) => {
          console.error("Error fetching regioes:", err);
        });
    } else {
      setRegioes([]);
      setSelectedRegiao("");
      setMiscelanias([]);
      setSelectedMiscelania("");
    }
  }, [selectedModalidade]);

  // Fetch miscelanias when regiao is selected
  useEffect(() => {
    if (selectedRegiao) {
      axios
        .get(`${API_BASE_URL}/miscelanias`)
        .then((response) => {
          setMiscelanias(response.data);
        })
        .catch((err) => {
          console.error("Error fetching miscelanias:", err);
        });
    } else {
      setMiscelanias([]);
      setSelectedMiscelania("");
    }
  }, [selectedRegiao]);

  const handleLaudoClick = (laudo) => {
    setSelectedLaudo(laudo);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedLaudo(null);
    setDialogOpen(false);
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  // Function to copy text to clipboard
  const handleCopy = () => {
    if (selectedLaudo && selectedLaudo.descricao) {
      navigator.clipboard
        .writeText(selectedLaudo.descricao)
        .then(() => {
          // Optionally, you can show a message that the text was copied
          console.log("Texto copiado para a área de transferência.");
        })
        .catch((err) => {
          console.error("Erro ao copiar o texto: ", err);
        });
    }
  };

  return (
    <div style={{ padding: muiTheme.spacing(4) }}>
      <Typography variant="h4" gutterBottom align="center">
        Máscaras de relatórios
      </Typography>
      <Grid container spacing={4}>
        {/* Search Box for Nome do Laudo */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Pesquisar Nome do Laudo"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: muiTheme.spacing(2) }}
          />
        </Grid>

        {/* Modalidade Selector */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: muiTheme.spacing(2) }}>
            <FormControl fullWidth>
              <InputLabel id="modalidade-label">Modalidade</InputLabel>
              <Select
                labelId="modalidade-label"
                value={selectedModalidade}
                label="Modalidade"
                onChange={(e) => setSelectedModalidade(e.target.value)}
              >
                {modalidades.map((mod) => (
                  <MenuItem key={mod.id} value={mod.id}>
                    {mod.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        {/* Regiao Selector */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: muiTheme.spacing(2) }}>
            <FormControl fullWidth disabled={!selectedModalidade}>
              <InputLabel id="regiao-label">Região</InputLabel>
              <Select
                labelId="regiao-label"
                value={selectedRegiao}
                label="Região"
                onChange={(e) => setSelectedRegiao(e.target.value)}
              >
                {regioes.map((reg) => (
                  <MenuItem key={reg.id} value={reg.id}>
                    {reg.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        {/* Miscelania Selector */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: muiTheme.spacing(2) }}>
            <FormControl fullWidth disabled={!selectedRegiao}>
              <InputLabel id="miscelania-label">Miscelânia</InputLabel>
              <Select
                labelId="miscelania-label"
                value={selectedMiscelania}
                label="Miscelânia"
                onChange={(e) => setSelectedMiscelania(e.target.value)}
              >
                {miscelanias.map((misc) => (
                  <MenuItem key={misc.id} value={misc.id}>
                    {misc.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>

      {/* Laudos Table */}
      {laudos.length > 0 ? (
        <div style={{ marginTop: muiTheme.spacing(4) }}>
          <Typography variant="h5" gutterBottom>
            Laudos Disponíveis
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: muiTheme.palette.secondary.main }}>
                  <TableCell style={{ color: "white" }}>
                    <strong>Nome</strong>
                  </TableCell>
                  <TableCell style={{ color: "white" }}>
                    <strong>Modalidade</strong>
                  </TableCell>
                  <TableCell style={{ color: "white" }}>
                    <strong>Região</strong>
                  </TableCell>
                  <TableCell style={{ color: "white" }}>
                    <strong>Miscelânia</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {laudos.map((laudo) => (
                  <TableRow
                    key={laudo.id}
                    hover
                    onClick={() => handleLaudoClick(laudo)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{laudo.nome}</TableCell>
                    <TableCell>{laudo.modalidade.nome}</TableCell>
                    <TableCell>{laudo.regiao_corpo.nome}</TableCell>
                    <TableCell>{laudo.miscelania.descricao}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {hasMore && (
            <Button
              onClick={handleLoadMore}
              variant="contained"
              style={{ marginTop: muiTheme.spacing(2) }}
            >
              Carregar Mais
            </Button>
          )}
        </div>
      ) : (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          style={{ marginTop: muiTheme.spacing(4) }}
        >
          Nenhum laudo com essa combinação
        </Typography>
      )}

      {/* Laudo Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedLaudo && selectedLaudo.nome}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{
              position: "absolute",
              right: muiTheme.spacing(1),
              top: muiTheme.spacing(1),
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedLaudo && (
            <TextField
              multiline
              fullWidth
              value={selectedLaudo.descricao}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy} variant="contained">
            Copiar Texto
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
