import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Container, Paper } from '@mui/material';

import Header from './components/Header';
import Menubar from './components/Menubar';
import Main from './components/Main';
import Footer from './components/Footer';

// New color scheme
const theme = createTheme({
  palette: {
    primary: {
      main: '#192936', // Dark navy blue
    },
    secondary: {
      main: '#547680', // Soft teal blue
    },
    background: {
      default: '#f5f5f5', // Light gray (unchanged)
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Header />
        <Menubar />
        <Container maxWidth="lg" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Main />
          </Paper>
        </Container>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
