import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function TermsOfService() {
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      <Typography variant="h4" gutterBottom>
        Termos de Serviço
      </Typography>
      <Box sx={{ textAlign: 'justify' }}>
        <Typography variant="body1" paragraph>
          <strong>Isenção de Garantias e Limites de Responsabilidade</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Este website é estritamente um recurso informativo. Ele não substitui
          a avaliação médica profissional, diagnósticos ou tratamentos. É vital
          entender que as informações e resultados aqui apresentados são
          fornecidos com o propósito de servir como um apoio informativo
          complementar, nunca como fundamentação única para decisões médicas. A
          validação e aplicação de qualquer dado disponibilizado devem ser
          feitas exclusivamente por profissionais de saúde qualificados, os
          quais são encorajados a verificar e confirmar a veracidade das
          informações antes de qualquer aplicação clínica.
        </Typography>
      </Box>
    </Container>
  );
}

export default TermsOfService;