import React, { useState, useEffect } from 'react';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  FormGroup,
  ListItemText,
  FormControlLabel,
  Box,
  Alert,
} from '@mui/material';

// Importar imagens (certifique-se de que as imagens estão na pasta correta)
import ExophyticEndophyticImage from './images/exophytic_endophytic.png';
import PolarLinesImage from './images/polar_lines.png';

// Criar tema MUI para espaçamento e cores
const theme = createTheme({
  palette: {
    primary: {
      main: '#192936', // Dark navy blue
    },
    secondary: {
      main: '#547680', // Soft teal blue
    },
    background: {
      default: '#f5f5f5', // Light gray (unchanged)
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default function RenalScoreCalculator() {
  // Variáveis de estado
  const [rimSide, setRimSide] = useState('');
  const [rimLocation, setRimLocation] = useState('');
  const [rimSize, setRimSize] = useState('');
  const [collectorSystem, setCollectorSystem] = useState('');
  const [parenchyma, setParenchyma] = useState([]);
  const [benignChanges, setBenignChanges] = useState([]);
  const [lesionType, setLesionType] = useState('');
  const [lesionMargins, setLesionMargins] = useState('');
  const [lesionFatComponent, setLesionFatComponent] = useState('');
  const [lesionT2Signal, setLesionT2Signal] = useState('');
  const [lesionEnhancementPattern, setLesionEnhancementPattern] = useState('');
  const [lesionNecrosisComponent, setLesionNecrosisComponent] = useState('');
  const [lesionDWIRestriction, setLesionDWIRestriction] = useState('');
  const [lesionDiameterCategory, setLesionDiameterCategory] = useState('');
  const [exophyticEndophytic, setExophyticEndophytic] = useState('');
  const [proximityToCollectorCategory, setProximityToCollectorCategory] = useState('');
  const [anteriorPosterior, setAnteriorPosterior] = useState('');
  const [polarLinesLocation, setPolarLinesLocation] = useState('');
  const [adjacentTissueContact, setAdjacentTissueContact] = useState([]);
  const [sizeChangeComparedToPrevious, setSizeChangeComparedToPrevious] = useState('');
  const [renalScore, setRenalScore] = useState(null);
  const [renalRisk, setRenalRisk] = useState('');
  const [structuredReport, setStructuredReport] = useState('');
  const [errors, setErrors] = useState({});

  // Opções para campos de seleção múltipla
  const parenchymaOptions = [
    'Preservado',
    'Lobulação Fetal',
    'Coluna de Bertin',
    'Fusão Congênita',
    'Alteração Rotacional',
  ];

  const benignChangesOptions = ['Cistos', 'Cálculos', 'Cicatrizes'];

  const adjacentTissueOptions = [
    'Gordura Perirrenal',
    'Fáscia Perirrenal',
    'Glândula Adrenal Ipsilateral',
  ];

  // Função para calcular o RENAL Score
  const calculateRenalScore = () => {
    let totalScore = 0;
    let errorMessages = {};

    // Validação dos campos obrigatórios
    if (!lesionDiameterCategory) {
      errorMessages.lesionDiameterCategory = 'Campo obrigatório';
    }
    if (!exophyticEndophytic) {
      errorMessages.exophyticEndophytic = 'Campo obrigatório';
    }
    if (!proximityToCollectorCategory) {
      errorMessages.proximityToCollectorCategory = 'Campo obrigatório';
    }
    if (!anteriorPosterior) {
      errorMessages.anteriorPosterior = 'Campo obrigatório';
    }
    if (!polarLinesLocation) {
      errorMessages.polarLinesLocation = 'Campo obrigatório';
    }

    if (Object.keys(errorMessages).length > 0) {
      setErrors(errorMessages);
      return;
    } else {
      setErrors({});
    }

    // Raio (R)
    if (lesionDiameterCategory === '≤ 4 cm (1 ponto)') {
      totalScore += 1;
    } else if (lesionDiameterCategory === '> 4 cm e ≤ 7 cm (2 pontos)') {
      totalScore += 2;
    } else if (lesionDiameterCategory === '> 7 cm (3 pontos)') {
      totalScore += 3;
    }

    // Endofítico/Exofítico (E)
    if (exophyticEndophytic === '≥ 50% Exofítico (1 ponto)') {
      totalScore += 1;
    } else if (exophyticEndophytic === '< 50% Exofítico (2 pontos)') {
      totalScore += 2;
    } else if (exophyticEndophytic === 'Completamente Endofítico (3 pontos)') {
      totalScore += 3;
    }

    // Proximidade ao Sistema Coletor (N)
    if (proximityToCollectorCategory === '> 7 mm (1 ponto)') {
      totalScore += 1;
    } else if (proximityToCollectorCategory === '4-7 mm (2 pontos)') {
      totalScore += 2;
    } else if (proximityToCollectorCategory === '< 4 mm (3 pontos)') {
      totalScore += 3;
    }

    // Localização em Relação às Linhas Polares (L)
    if (polarLinesLocation === 'Totalmente acima/abaixo da linha polar (1 ponto)') {
      totalScore += 1;
    } else if (polarLinesLocation === 'Cruzando as linhas polares (2 pontos)') {
      totalScore += 2;
    } else if (polarLinesLocation === '> 50% cruza a linha polar/linha média axial (3 pontos)') {
      totalScore += 3;
    }

    setRenalScore(totalScore);

    // Determinar a categoria de risco
    if (totalScore >= 4 && totalScore <= 6) {
      setRenalRisk('Baixo Risco');
    } else if (totalScore >= 7 && totalScore <= 9) {
      setRenalRisk('Risco Moderado');
    } else if (totalScore >= 10 && totalScore <= 12) {
      setRenalRisk('Alto Risco');
    } else {
      setRenalRisk('');
    }
  };

  // Recalcular o RENAL Score sempre que os campos relevantes mudarem
  useEffect(() => {
    calculateRenalScore();
    generateStructuredReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rimSide,
    rimLocation,
    rimSize,
    collectorSystem,
    parenchyma,
    benignChanges,
    lesionType,
    lesionMargins,
    lesionFatComponent,
    lesionT2Signal,
    lesionEnhancementPattern,
    lesionNecrosisComponent,
    lesionDWIRestriction,
    lesionDiameterCategory,
    exophyticEndophytic,
    proximityToCollectorCategory,
    anteriorPosterior,
    polarLinesLocation,
    adjacentTissueContact,
    sizeChangeComparedToPrevious,
  ]);

  // Função para lidar com seleção múltipla
  const handleMultipleSelectChange = (item, setFunction, stateArray) => {
    const currentIndex = stateArray.indexOf(item);
    const newChecked = [...stateArray];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFunction(newChecked);
  };

  // Função para copiar texto para a área de transferência
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  // Função para gerar o laudo estruturado
  const generateStructuredReport = () => {
    const reportLines = [];

    if (rimSide) reportLines.push(`${rimSide}`);
    if (rimLocation) reportLines.push(`Localização: ${rimLocation}`);
    if (rimSize) reportLines.push(`Tamanho do rim: ${rimSize} cm.`);
    if (collectorSystem) reportLines.push(`Sistema coletor: ${collectorSystem}`);
    if (parenchyma.length > 0) reportLines.push(`Parênquima: ${parenchyma.join(', ')}`);
    if (benignChanges.length > 0)
      reportLines.push(`Alterações benignas: ${benignChanges.join(', ')}`);

    reportLines.push(`Lesão:`);
    if (lesionType) reportLines.push(`- ${lesionType}`);
    if (lesionMargins) reportLines.push(`- Margens ${lesionMargins}`);
    if (lesionFatComponent)
      reportLines.push(
        `- ${lesionFatComponent} componente de gordura macroscópica ou microscópica associada`
      );
    if (lesionT2Signal)
      reportLines.push(`- ${lesionT2Signal} nas sequências ponderadas em T2`);
    if (lesionEnhancementPattern)
      reportLines.push(`- Padrão de realce ${lesionEnhancementPattern}`);
    if (lesionNecrosisComponent)
      reportLines.push(`- ${lesionNecrosisComponent} componente de necrose associado`);
    if (lesionDWIRestriction)
      reportLines.push(`- ${lesionDWIRestriction} restrição na DWI`);

    const anteriorPosteriorCode =
      anteriorPosterior === 'Anterior'
        ? 'a'
        : anteriorPosterior === 'Posterior'
        ? 'p'
        : anteriorPosterior === 'Indeterminado'
        ? 'x'
        : '';

    reportLines.push(
      `RENAL SCORE: ${
        renalScore !== null ? `${renalScore}${anteriorPosteriorCode}` : 'N/A'
      } (${renalRisk})`
    );
    if (lesionDiameterCategory) reportLines.push(`- Raio: ${lesionDiameterCategory}`);
    if (sizeChangeComparedToPrevious)
      reportLines.push(
        `- ${sizeChangeComparedToPrevious} mudança de tamanho em relação ao exame prévio`
      );

    reportLines.push(`Localização:`);
    if (polarLinesLocation) reportLines.push(`- Polar: ${polarLinesLocation}`);
    if (anteriorPosterior) reportLines.push(`- Axial: ${anteriorPosterior}`);
    if (exophyticEndophytic) reportLines.push(`- Capsular: ${exophyticEndophytic}`);
    if (proximityToCollectorCategory)
      reportLines.push(
        `- Proximidade com sistema coletor ou seio renal: ${proximityToCollectorCategory}`
      );

    if (adjacentTissueContact.length > 0)
      reportLines.push(
        `Contato ou invasão da lesão com tecidos adjacentes: Há invasão de ${adjacentTissueContact.join(
          ', '
        )}`
      );
    else
      reportLines.push(
        `Contato ou invasão da lesão com tecidos adjacentes: Não há invasão`
      );

    setStructuredReport(reportLines.join('\n'));
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: theme.spacing(2), textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Estadiamento de neoplasia renal
        </Typography>

        <Grid container spacing={2}>
          {/* Lado do Rim */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Lado do Rim*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={rimSide}
                  exclusive
                  onChange={(e, value) => setRimSide(value)}
                  fullWidth
                >
                  <ToggleButton value="Rim Direito">Rim Direito</ToggleButton>
                  <ToggleButton value="Rim Esquerdo">Rim Esquerdo</ToggleButton>
                </ToggleButtonGroup>
                {errors.rimSide && <Alert severity="error">{errors.rimSide}</Alert>}
              </FormControl>
            </Paper>
          </Grid>

          {/* Localização do Rim */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Localização do Rim*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={rimLocation}
                  exclusive
                  onChange={(e, value) => setRimLocation(value)}
                  fullWidth
                >
                  <ToggleButton value="Tópico">Tópico</ToggleButton>
                  <ToggleButton value="Ectópico">Ectópico</ToggleButton>
                </ToggleButtonGroup>
                {errors.rimLocation && (
                  <Alert severity="error">{errors.rimLocation}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Tamanho do Rim */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <TextField
                label="Tamanho do Rim (cm)*"
                fullWidth
                type="number"
                inputProps={{ min: 5, max: 20 }}
                value={rimSize}
                onChange={(e) => setRimSize(e.target.value)}
              />
              {errors.rimSize && <Alert severity="error">{errors.rimSize}</Alert>}
            </Paper>
          </Grid>

          {/* Sistema Coletor */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Sistema Coletor*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={collectorSystem}
                  exclusive
                  onChange={(e, value) => setCollectorSystem(value)}
                  fullWidth
                >
                  <ToggleButton value="Normal">Normal</ToggleButton>
                  <ToggleButton value="Duplicado">Duplicado</ToggleButton>
                </ToggleButtonGroup>
                {errors.collectorSystem && (
                  <Alert severity="error">{errors.collectorSystem}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Parênquima */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Parênquima</FormLabel>
                <FormGroup row>
                  {parenchymaOptions.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={parenchyma.indexOf(item) > -1}
                          onChange={() =>
                            handleMultipleSelectChange(item, setParenchyma, parenchyma)
                          }
                          color="primary"
                        />
                      }
                      label={item}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Paper>
          </Grid>

          {/* Alterações Benignas */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Alterações Benignas</FormLabel>
                <FormGroup row>
                  {benignChangesOptions.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={benignChanges.indexOf(item) > -1}
                          onChange={() =>
                            handleMultipleSelectChange(
                              item,
                              setBenignChanges,
                              benignChanges
                            )
                          }
                          color="primary"
                        />
                      }
                      label={item}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Paper>
          </Grid>

          {/* Detalhes da Lesão */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Detalhes da Lesão
            </Typography>
          </Grid>

          {/* Tipo de Lesão */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Tipo de Lesão*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionType}
                  exclusive
                  onChange={(e, value) => setLesionType(value)}
                  fullWidth
                >
                  <ToggleButton value="Sólida">Sólida</ToggleButton>
                  <ToggleButton value="Cística">Cística</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionType && (
                  <Alert severity="error">{errors.lesionType}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Margens da Lesão */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Margens*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionMargins}
                  exclusive
                  onChange={(e, value) => setLesionMargins(value)}
                  fullWidth
                >
                  <ToggleButton value="Bem Definidas">Bem Definidas</ToggleButton>
                  <ToggleButton value="Infiltrativas">Infiltrativas</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionMargins && (
                  <Alert severity="error">{errors.lesionMargins}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Componente de Gordura */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Componente de Gordura*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionFatComponent}
                  exclusive
                  onChange={(e, value) => setLesionFatComponent(value)}
                  fullWidth
                >
                  <ToggleButton value="Há">Há</ToggleButton>
                  <ToggleButton value="Não há">Não há</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionFatComponent && (
                  <Alert severity="error">{errors.lesionFatComponent}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Sinal em T2 */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Sinal em T2*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionT2Signal}
                  exclusive
                  onChange={(e, value) => setLesionT2Signal(value)}
                  fullWidth
                >
                  <ToggleButton value="Hipersinal">Hipersinal</ToggleButton>
                  <ToggleButton value="Isossinal">Isossinal</ToggleButton>
                  <ToggleButton value="Hipossinal">Hipossinal</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionT2Signal && (
                  <Alert severity="error">{errors.lesionT2Signal}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Padrão de Realce */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Padrão de Realce*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionEnhancementPattern}
                  exclusive
                  onChange={(e, value) => setLesionEnhancementPattern(value)}
                  fullWidth
                >
                  <ToggleButton value="Homogêneo">Homogêneo</ToggleButton>
                  <ToggleButton value="Heterogêneo">Heterogêneo</ToggleButton>
                  <ToggleButton value="Hipervascular">Hipervascular</ToggleButton>
                  <ToggleButton value="Hipovascular">Hipovascular</ToggleButton>
                  <ToggleButton value="Invertido">Invertido</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionEnhancementPattern && (
                  <Alert severity="error">
                    {errors.lesionEnhancementPattern}
                  </Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Componente de Necrose */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Componente de Necrose*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionNecrosisComponent}
                  exclusive
                  onChange={(e, value) => setLesionNecrosisComponent(value)}
                  fullWidth
                >
                  <ToggleButton value="Há">Há</ToggleButton>
                  <ToggleButton value="Não há">Não há</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionNecrosisComponent && (
                  <Alert severity="error">{errors.lesionNecrosisComponent}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Restrição na DWI */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Restrição na DWI*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionDWIRestriction}
                  exclusive
                  onChange={(e, value) => setLesionDWIRestriction(value)}
                  fullWidth
                >
                  <ToggleButton value="Há">Há</ToggleButton>
                  <ToggleButton value="Não há">Não há</ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionDWIRestriction && (
                  <Alert severity="error">{errors.lesionDWIRestriction}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Ilustração Exofítico/Endofítico */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <Typography variant="h6">
                Ilustração: Tipos de Lesões Exofíticas e Endofíticas
              </Typography>
              <img
                src={ExophyticEndophyticImage}
                alt="Ilustração de lesões exofíticas e endofíticas"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
              <Typography variant="caption">
                *Figura ilustrando as diferentes classificações de lesões quanto à sua
                posição em relação ao parênquima renal.
              </Typography>
            </Paper>
          </Grid>

          {/* Cálculo do RENAL Score */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Cálculo do RENAL Score
            </Typography>
          </Grid>

          {/* Diâmetro da Lesão */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Raio*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={lesionDiameterCategory}
                  exclusive
                  onChange={(e, value) => setLesionDiameterCategory(value)}
                  fullWidth
                >
                  <ToggleButton value="≤ 4 cm (1 ponto)">≤ 4 cm (1 ponto)</ToggleButton>
                  <ToggleButton value="> 4 cm e ≤ 7 cm (2 pontos)">
                    &gt; 4 cm e ≤ 7 cm (2 pontos)
                  </ToggleButton>
                  <ToggleButton value="> 7 cm (3 pontos)">
                    &gt; 7 cm (3 pontos)
                  </ToggleButton>
                </ToggleButtonGroup>
                {errors.lesionDiameterCategory && (
                  <Alert severity="error">{errors.lesionDiameterCategory}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Exofítico/Endofítico */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Endofítico/Exofítico*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={exophyticEndophytic}
                  exclusive
                  onChange={(e, value) => setExophyticEndophytic(value)}
                  fullWidth
                >
                  <ToggleButton value="≥ 50% Exofítico (1 ponto)">
                    ≥ 50% Exofítico (1 ponto)
                  </ToggleButton>
                  <ToggleButton value="< 50% Exofítico (2 pontos)">
                    &lt; 50% Exofítico (2 pontos)
                  </ToggleButton>
                  <ToggleButton value="Completamente Endofítico (3 pontos)">
                    Completamente Endofítico (3 pontos)
                  </ToggleButton>
                </ToggleButtonGroup>
                {errors.exophyticEndophytic && (
                  <Alert severity="error">{errors.exophyticEndophytic}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Proximidade ao Sistema Coletor */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Proximidade ao Sistema Coletor*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={proximityToCollectorCategory}
                  exclusive
                  onChange={(e, value) => setProximityToCollectorCategory(value)}
                  fullWidth
                >
                  <ToggleButton value="> 7 mm (1 ponto)">
                    &gt; 7 mm (1 ponto)
                  </ToggleButton>
                  <ToggleButton value="4-7 mm (2 pontos)">4-7 mm (2 pontos)</ToggleButton>
                  <ToggleButton value="< 4 mm (3 pontos)">
                    &lt; 4 mm (3 pontos)
                  </ToggleButton>
                </ToggleButtonGroup>
                {errors.proximityToCollectorCategory && (
                  <Alert severity="error">
                    {errors.proximityToCollectorCategory}
                  </Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Anterior/Posterior */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Anterior/Posterior*</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={anteriorPosterior}
                  exclusive
                  onChange={(e, value) => setAnteriorPosterior(value)}
                  fullWidth
                >
                  <ToggleButton value="Anterior">Anterior (a)</ToggleButton>
                  <ToggleButton value="Posterior">Posterior (p)</ToggleButton>
                  <ToggleButton value="Indeterminado">Indeterminado (x)</ToggleButton>
                </ToggleButtonGroup>
                {errors.anteriorPosterior && (
                  <Alert severity="error">{errors.anteriorPosterior}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Localização em Relação às Linhas Polares */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  Localização em Relação às Linhas Polares*
                </FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={polarLinesLocation}
                  exclusive
                  onChange={(e, value) => setPolarLinesLocation(value)}
                  fullWidth
                >
                  <ToggleButton value="Totalmente acima/abaixo da linha polar (1 ponto)">
                    Totalmente acima/abaixo da linha polar (1 ponto)
                  </ToggleButton>
                  <ToggleButton value="Cruzando as linhas polares (2 pontos)">
                    Cruzando as linhas polares (2 pontos)
                  </ToggleButton>
                  <ToggleButton value="> 50% cruza a linha polar/linha média axial (3 pontos)">
                    &gt; 50% cruza a linha polar/linha média axial (3 pontos)
                  </ToggleButton>
                </ToggleButtonGroup>
                {errors.polarLinesLocation && (
                  <Alert severity="error">{errors.polarLinesLocation}</Alert>
                )}
              </FormControl>
            </Paper>
          </Grid>

          {/* Ilustração Linhas Polares */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <Typography variant="h6">
                Ilustração: Localização em Relação às Linhas Polares
              </Typography>
              <img
                src={PolarLinesImage}
                alt="Ilustração da localização das lesões em relação às linhas polares"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
              <Typography variant="caption">
                *Figura mostrando como as lesões se posicionam em relação às linhas polares
                superior e inferior do rim.
              </Typography>
            </Paper>
          </Grid>

          {/* Invasão de Tecidos Adjacentes */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  Contato ou Invasão com Tecidos Adjacentes
                </FormLabel>
                <FormGroup row>
                  {adjacentTissueOptions.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={adjacentTissueContact.indexOf(item) > -1}
                          onChange={() =>
                            handleMultipleSelectChange(
                              item,
                              setAdjacentTissueContact,
                              adjacentTissueContact
                            )
                          }
                          color="primary"
                        />
                      }
                      label={item}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Paper>
          </Grid>

          {/* Mudança de Tamanho em Relação ao Exame Prévio */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  Mudança de Tamanho em Relação ao Exame Prévio
                </FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={sizeChangeComparedToPrevious}
                  exclusive
                  onChange={(e, value) => setSizeChangeComparedToPrevious(value)}
                  fullWidth
                >
                  <ToggleButton value="Apresentou">Apresentou</ToggleButton>
                  <ToggleButton value="Não apresentou">Não apresentou</ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Paper>
          </Grid>

          {/* Resultado do RENAL Score */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <Typography variant="h5">
                RENAL Score:{' '}
                {renalScore !== null
                  ? `${renalScore}${
                      anteriorPosterior === 'Anterior'
                        ? 'a'
                        : anteriorPosterior === 'Posterior'
                        ? 'p'
                        : anteriorPosterior === 'Indeterminado'
                        ? 'x'
                        : ''
                    }`
                  : 'N/A'}
              </Typography>
              {renalRisk && (
                <Typography variant="h6">Classificação: {renalRisk}</Typography>
              )}
            </Paper>
          </Grid>

          {/* Laudo Estruturado */}
          <Grid item xs={12}>
            <Paper style={{ padding: theme.spacing(2) }}>
              <Typography variant="h6" gutterBottom>
                Laudo Estruturado
              </Typography>
              <TextField
                multiline
                fullWidth
                rows={10}
                value={structuredReport}
                variant="outlined"
              />
            </Paper>
          </Grid>

          {/* Botão para Copiar o Laudo */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => copyToClipboard(structuredReport)}
              sx={{ marginTop: theme.spacing(2) }}
            >
              Copiar Laudo para Área de Transferência
            </Button>
          </Grid>

          {/* Referências */}
          <Grid item xs={12}>
            <Box mt={4} textAlign="left">
              <Typography variant="h6">Fontes:</Typography>
              <Typography variant="body2">
                1. Alsaikhan N, Alshehri W, Cassidy F, Aganovic L, Vahdat N. Renal tumor
                structured reporting including nephrometry score and beyond: what the
                urologist and interventional radiologist need to know. Abdom Radiol. janeiro
                de 2019;44(1):190–200.
              </Typography>
              <Typography variant="body2">
                2. For the SAR Disease-Focused Panel on RCC, Davenport MS, Hu EM, Zhang A,
                Shinagare AB, Smith AD, et al. Standardized report template for indeterminate
                renal masses at CT and MRI: a collaborative product of the SAR
                Disease-Focused Panel on Renal Cell Carcinoma. Abdom Radiol. Abril de
                2019;44(4):1423–9.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}






/* 

ver a invasao dos vasos para a classifcacao do renal score




Descriptions of the Illustrative Figures:





Exophytic/Endophytic Image (exophytic_endophytic.png):
Description: An illustration showing examples of exophytic, partially exophytic, and endophytic renal lesions in relation to the kidney surface.
Purpose: Helps users visually understand how to classify lesions based on their depth and protrusion from the kidney surface.
Action: Place an image named exophytic_endophytic.png in the ./images/ folder of your project.


Polar Lines Image (polar_lines.png):
Description: An illustration depicting the upper and lower polar lines of the kidney, showing how lesions are positioned in relation to these lines.
Purpose: Assists users in determining the correct classification for the "Localização em Relação às Linhas Polares" criterion.
Action: Place an image named polar_lines.png in the ./images/ folder of your project.
Instructions for Adding the Images:

Step 1: Create an images folder within the same directory as your component file if it doesn't already exist.

Step 2: Find or create images that match the descriptions provided above.
You might find these images in medical textbooks, journals, or online medical resources that allow the use of their images.
Ensure you have the rights or permissions to use these images in your application.

Step 3: Save the images with the exact filenames:
exophytic_endophytic.png
polar_lines.png

Step 4: Place these images in the ./images/ folder of your project.

Step 5: The component will automatically display these images in the appropriate sections. */