import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Typography, Grid } from '@mui/material';

export default function SpeechToText() {
  const [isListening, setIsListening] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [copyMessage, setCopyMessage] = useState(false);
  const textFieldRef = useRef(null);
  const recognitionRef = useRef(null);

  useEffect(() => {
    // Check if the browser supports SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error('Este navegador não suporta reconhecimento de voz.');
      return;
    }

    // Initialize recognition
    const recognition = new SpeechRecognition();
    recognition.lang = 'pt-BR';
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      let finalTranscript = '';
      let interimTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
        } else {
          interimTranscript += transcript;
        }
      }

      if (finalTranscript) {
        const correctedTranscript = applyCorrections(finalTranscript);
        setTranscription((prev) => prev + correctedTranscript);
      }
      setInterimTranscript(interimTranscript);
    };

    recognition.onerror = (event) => {
      console.error('Ocorreu um erro no reconhecimento:', event.error);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognitionRef.current = recognition;

    // Cleanup on unmount
    return () => {
      recognition.stop();
    };
  }, []);

  // Function to apply text corrections
  const applyCorrections = (text) => {
    // Correct sentence start capitalization
    text = text.replace(/(^\w|[.!?]\s*\w)/g, (match) => match.toUpperCase());

    // Lowercase words in the middle of a sentence
    text = text.replace(/([.!?]\s*\w*|\b)(\w+)/g, (match, p1, p2) => {
      if (p1.trim() === '' || p1.endsWith('.') || p1.endsWith('?') || p1.endsWith('!')) {
        return match;
      }
      return p1 + p2.toLowerCase();
    });

    // Correct vertebrae patterns like "C1 C2", "T12 L1", etc., to "C1-C2", "T12-L1"
    text = text.replace(/([CTLS]\d+)\s([CTLS]\d+)/g, '$1-$2');

    // Handle measurement patterns and calculate volume
    text = text.replace(/(\d+,\d+)\s*por\s*(\d+,\d+)\s*por\s*(\d+,\d+)\s*(cm|centímetros)/gi, (match, p1, p2, p3) => {
      // Convert strings to floats
      const a = parseFloat(p1.replace(',', '.'));
      const b = parseFloat(p2.replace(',', '.'));
      const c = parseFloat(p3.replace(',', '.'));
      // Calculate the volume
      const volume = (a * b * c * 0.52).toFixed(2);
      // Return the formatted string with volume
      return `${p1} x ${p2} x ${p3} cm (volume estimado de ${volume} cm³)`;
    });

    // Ensure no spaces before punctuation marks (e.g., before ".", ",")
    text = text.replace(/\s+([.,?!])/g, '$1');

    // Replace the word "ponto" with a period and capitalize the next word
    text = text.replace(/\bponto\b/gi, '.');
    text = text.replace(/\. (\w)/g, (match, p1) => `. ${p1.toUpperCase()}`);

    // Replace the word "interrogação" with a question mark and capitalize the next word
    text = text.replace(/\binterrogação\b/g, '?');
    text = text.replace(/\? (\w)/g, (match, p1) => `? ${p1.toUpperCase()}`);

    // Replace the word "vírgula" with a comma for text, ensuring proper spacing only if followed by a letter
    text = text.replace(/\bvírgula\b/g, ',');
    text = text.replace(/,(\S)/g, (match, p1) => {
      if (/[a-zA-Z]/.test(p1)) {
        return `, ${p1}`; // Add space if followed by a letter
      }
      return `,${p1}`; // No space if followed by a number
    });

    // Ensure correct spacing before and after punctuation
    text = text.replace(/\.([a-zA-Z])/g, '. $1'); // Add space after periods if followed by a letter
    text = text.replace(/,([a-zA-Z])/g, ', $1'); // Add space after commas if followed by a letter

    // Ensure no double spaces
    text = text.replace(/\s\s+/g, ' ');

    // Trim any trailing spaces at the start or end
    text = text.trim();

    return text;
  };


  const startListening = () => {
    if (recognitionRef.current && !isListening) {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognitionRef.current && isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
      setInterimTranscript('');
    }
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(transcription.trim()).then(
      () => {
        setCopyMessage(true);
        setTimeout(() => setCopyMessage(false), 2000);
      },
      (err) => {
        console.error('Erro ao copiar texto:', err);
      }
    );
  };

  const clearText = () => {
    setTranscription('');
    setInterimTranscript('');
  };

  // Autoscroll to the bottom when transcription updates
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
  }, [transcription, interimTranscript]);

  return (
    <div style={{ padding: 20, textAlign: 'center' }}>
      <Typography variant='h4' gutterBottom>
        Transcrição de Voz (em desenvolvimento)
      </Typography>
      <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
        <TextField
          label='Transcrição'
          fullWidth
          value={`${transcription}${interimTranscript}`}
          onChange={(e) => setTranscription(e.target.value)}
          multiline
          rows={10}
          variant='outlined'
          inputRef={textFieldRef}
          style={{ overflowY: 'auto', maxHeight: 300 }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: 8,
            left: 8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button variant='contained' color='success' onClick={copyToClipboard}>
            Copiar Texto
          </Button>
          {copyMessage && (
            <Typography variant='body2' style={{ marginLeft: 10, color: 'green' }}>
              Texto copiado!
            </Typography>
          )}
        </div>
      </div>
      <Grid container spacing={2} justifyContent='center' style={{ marginTop: 20 }}>
        <Grid item>
          <Button
            variant='contained'
            color={isListening ? 'secondary' : 'primary'}
            onClick={toggleListening}
          >
            {isListening ? 'Parar de Ouvir' : 'Iniciar Ouvir'}
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='error' onClick={clearText}>
            Limpar
          </Button>
        </Grid>
      </Grid>
      {/* Detailed Instructions */}
      <Typography variant='body2' style={{ marginTop: 20, color: 'gray', fontSize: '0.85rem', textAlign: 'left' }}>
        <strong>Instruções para transcrição de voz:</strong>
        <ol style={{ paddingLeft: '20px' }}>
          <li><strong>Falar claramente:</strong> Pronuncie cada palavra de forma clara e pausada. Isso ajuda a evitar erros de reconhecimento de voz.</li>
          <li><strong>Pausas naturais entre frases:</strong> Dê pausas curtas ao final de cada frase para que o sistema identifique corretamente pontos finais.</li>
          <li><strong>Medidas e dimensões:</strong> Para medidas como "3,2 por 2,0 por 1,5 centímetros," fale claramente os números e unidades, usando "por" para separar as dimensões.</li>
          <ul>
            <li><strong>Volume calculado automaticamente:</strong> Quando você falar três medidas no formato "<em>A por B por C centímetros</em>", o sistema calculará automaticamente o volume e incluirá na transcrição (exemplo: "3,2 por 2,0 por 1,5 cm" será convertido para "3,2 x 2,0 x 1,5 cm (volume estimado de 2.50 cm³)").</li>
          </ul>
          <li><strong>Pontuações:</strong> Use "ponto" para adicionar um ponto final (.) e iniciar a próxima frase com letra maiúscula. Diga "vírgula" para adicionar uma vírgula (,) na transcrição.</li>
          <li><strong>Comportamento das vírgulas:</strong> Quando você fala "vírgula," ela será convertida para ",". Se seguida por uma letra, haverá um espaço após a vírgula. Se seguida por um número (como em medidas), a vírgula será inserida sem espaço.</li>
          <li><strong>Termos anatômicos e estruturas:</strong> Ao mencionar vértebras como "C1 C2" ou "L4 L5," use uma pausa breve entre as palavras. O sistema transformará automaticamente para o formato correto (exemplo: "C1 C2" será transcrito como "C1-C2").</li>
        </ol>
      </Typography>
    </div>
  );

}
